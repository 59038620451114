import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import {
  checkCanOfferDriver,
  checkShowUnassignVehicleBtn,
  getBookingType,
  getVehicleAndDriverOfBooks,
  regexpDriverAutoComplete,
} from '../../../utils/commonFunctions';
import { isCanAssignOfflineDriver } from '../../../components/bookingDetail/bookFunction/serviceRequest';
import timeIcon from '../../../assets/images/icons/time.svg';
import * as newbookingActions from '../../../actions/newbookingAction';
import * as driverAutocompleteActions from '../../../actions/driverAutocompleteActions';
import * as driverActions from '../../../actions/driverAction';
import * as settingActions from '../../../actions/settingActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { BOOK_TYPE, GROUP_ACTIONS, thirdPartyIntegration, userType } from '../../../constants/commondata';
import VehicleAutoComplete from '../../../components/bookingDetail/bookComponent/VehicleAutocomplete';
import { getVehicleWhenChangeDriver } from '../../../components/bookingDetail/bookFunction/bookingInfo';
import { tryEach } from 'async';
import { verifyStatusShowUnassignAllBtn } from '../cueFunctions';

let lastAutoCompleteRequestId;

const AutosuggestAssignDriver = ({
  books,
  disabled,
  auth,
  openAssignDriverModal,
  handleAssignDriverClose,
  user,
  ...props
}) => {
  const [driverSuggestions, setDriverSuggestions] = useState([]);
  const [vehicleState, setVehicleState] = useState({});
  const [dataMultiBook, setDataMultiBook] = useState({
    vehicles: [],
    vehicleTypes: [],
    vehicleTypesNotAssociate: [],
    vehicleTypesAssociate: [],
  });
  const [driverBooks, setDriverBooks] = useState([]);
  const [fullName, setFullName] = useState();
  const [isMultiBook, setMultiBook] = useState(false);
  const [driverSelected, setDriverSelected] = useState();
  const [canOfferDriver, setCanOfferDriver] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [showUnAssignVehicle, setShowUnassignVehicle] = useState(false);
  const [pickupTimeState, setPickupTime] = useState('Now');
  const [isCorpBoard, setIsCorpBoard] = useState(false);

  useEffect(() => {
    if (books?.length > 1) setMultiBook(true);
    if (checkCanOfferDriver(books?.[0])) setCanOfferDriver(true);
    setSupplierList(getSupplierFromMultiBook());

    const {
      vhcType,
      drvs,
      vhc = [],
      vhcTypeAssociate = [],
    } = getVehicleAndDriverOfBooks(books);
    if (drvs?.length > 0) getDriverInfoById(drvs);
    setDataMultiBook({
      vehicleTypes: [...vhcType, ...vhcTypeAssociate],
      vehicleTypesNotAssociate: [...vhcType],
      vehicleTypesAssociate: [...vhcType],
      vehicles: vhc,
    });
    setShowUnassignVehicle(checkShowUnassignVehicleBtn(books));

    // Case only 1 book
    if (books?.length === 1) {
      if (books[0]?.drvInfo?.phone) {
        setFullName(
          books[0]?.drvInfo?.fullName || books[0]?.drvInfo?.firstName
        );
      }
      if (books[0]?.vehicle?.plateNumber) {
        setVehicleState(books[0]?.vehicle);
      }
    }
    setPickupTime(books?.[0]?.request?.pickUpTime || 'Now');
    setIsCorpBoard(
      user?.userType == userType.CorporateAdmin ||
        user?.userType == userType.CorporateUser
    );
  }, []);

  const getDriverInfoById = async (drvs) => {
    try {
      const result = await props.driverActions.operationFindById({
        fleetId: auth.selectedFleet.fleetId,
        driverIds: drvs,
      });
      if (result.ok && result.res.list) {
        setDriverBooks(result.res.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderDriverSuggestion = (suggestion) => {
    let { status } = suggestion;
    if (!suggestion.online) {
      status = 'offline';
    } else if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0]
        ? suggestion.currentJob[0].status
        : suggestion.status;
    }

    let pickUpTime = '';
    if (!isMultiBook) {
      pickUpTime = books?.[0]?.time;
    }

    return (
      <div className={`suggestion-content driver-assign ${status}`}>
        <div className="status" />
        <div className="info">
          <span className="">
            {`${suggestion.firstName} ${suggestion.lastName}${
              suggestion.company ? ` / ${suggestion.company.companyName}` : ''
            }`}
          </span>
          <span>{`${suggestion.vehicle.plateNumber} / ${suggestion.phone}`}</span>
        </div>
      </div>
    );
  };

  const getListDriverOnline = (escapedValue) => {
    // for 1 book
    const { selectedFleet } = auth;
    const offlineDriver = isCanAssignOfflineDriver(
      selectedFleet,
      pickupTimeState
    );
    props.driverAutocompleteActions
      .driverAutocomplete({
        fleetId: auth.selectedFleet.fleetId,
        str: escapedValue,
        pickUpTime:
          pickupTimeState == 'Now'
            ? 'Now'
            : moment(pickupTimeState).format('YYYY-MM-DD HH:mm'),
        vehicleType: books?.[0]?.request?.vehicleTypeRequest,
        supplierCompanies: books?.[0]?.supplierCompanies,
        vehicleId: books?.[0]?.vehicle?.vehicleId || '',
        offlineDriver,
        corporateId: books?.[0]?.corporateInfo?.corporateId,
      })
      .then((data) => {
        setDriverSuggestions(
          regexpDriverAutoComplete(data?.res?.list || [], escapedValue)
        );
      });
  };

  const getListDriverOnlineMultiBook = (escapedValue) => {
    // for multi book
    const { selectedFleet } = auth;
    const offlineDriver = isCanAssignOfflineDriver(selectedFleet);
    props.driverAutocompleteActions
      .driverAutocompleteMultiBook({
        fleetId: auth.selectedFleet.fleetId,
        str: escapedValue,
        vehicleTypes: dataMultiBook.vehicleTypes,
        supplierCompanies: supplierList,
        vehicleIds: dataMultiBook.vehicles?.map((d) => d.vehicleId) || [],
        offlineDriver,
      })
      .then((data) => {
        setDriverSuggestions(
          regexpDriverAutoComplete(data?.res?.list || [], escapedValue)
        );
      });
  };

  const getSupplierFromMultiBook = () => {
    if (books.some((bk) => bk?.supplierCompanies?.length === 0)) {
      return [];
    }
    return _.compact(_.uniq(_.flatMap(books, 'supplierCompanies')));
  };

  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const onDriverSuggestionsFetchRequested = ({ value }) => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (lastAutoCompleteRequestId !== null) {
      clearTimeout(lastAutoCompleteRequestId);
    }

    lastAutoCompleteRequestId = setTimeout(() => {
      if (isMultiBook) {
        getListDriverOnlineMultiBook(escapedValue);
      } else {
        getListDriverOnline(escapedValue);
      }
    }, 300);
    const regex = new RegExp(`\\b${escapedValue}`, 'i');
    const data = regexpDriverAutoComplete(
      props.driverAutocomplete.data,
      escapedValue
    );
    return data;
  };

  const onDriverSuggestionsClearRequested = () => {
    setDriverSuggestions([]);
  };

  const getDriverSuggestionValue = (suggestion) => {
    return suggestion.driver.name;
  };

  const driverAutocompleteSlectedHandle = (__, { suggestion }) => {
    if (lastAutoCompleteRequestId !== null) {
      clearTimeout(lastAutoCompleteRequestId);
    }
    if (suggestion?.driverId?._id) {
      setFullName(`${suggestion.firstName || ''} ${suggestion.lastName}`);
      setDriverSelected(suggestion);
      setVehicleState(
        getVehicleWhenChangeDriver(
          {
            oldVehicle: vehicleState,
            pickupTime: pickupTimeState,
            drvInfo: suggestion,
            vehicleTypes: dataMultiBook.vehicleTypesNotAssociate,
            associateCarType: dataMultiBook.vehicleTypesAssociate,
          }
        )
      );
    }
  };

  const checkErr3rdBook = () => {
    if (
      books?.[0]?.externalInfo?.thirdParty ===
        thirdPartyIntegration.bookingAPI &&
      !vehicleState.vehicleId
    ) {
      props.notification('error', 'The vehicle is required');
      return true;
    }
    return false;
  };

  const handleDriverChanged = (e) => {
    setFullName(e.target.value);
  };

  const checkShowDetachBtn = () => {
    const bookType = getBookingType(books?.[0]);
    // don't detach with book now
    if (
      books.length === 1 &&
      (bookType === BOOK_TYPE.now ||
        (bookType === BOOK_TYPE.rideSharing && pickupTimeState === 'Now'))
    ) {
      return false;
    }

    if (!(driverBooks?.length > 0)) return false;

    return true;
  };

  const handleAssignDriver = () => {
    if(checkErr3rdBook()) return
    if (
      driverSelected?.driverId?._id ||
      (pickupTimeState !== 'Now' && vehicleState._id)
    ) {
      props.assignDriver(driverSelected?.driverId?._id, vehicleState._id);
    }
  };

  const handleOfferDriver = () => {
    if (
      pickupTimeState !== 'Now' &&
      (driverSelected?.driverId?._id || vehicleState.vehicleId)
    ) {
      props.offerDriver(driverSelected?.driverId?._id, vehicleState.vehicleId);
    }
  };

  return (
    <Modal
      show={true}
      dialogClassName="confirm-dialog modalAssign"
      backdrop="static"
      className="confirm groupOrderModal"
      onHide={handleAssignDriverClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate value="cue.selectDriver" className="transform-none" />
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleAssignDriverClose}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <p className="searchTitle">{I18n.t('General.search')}</p>
        <div className="add-on-input-group">
          <Autosuggest
            suggestions={driverSuggestions}
            onSuggestionsFetchRequested={onDriverSuggestionsFetchRequested}
            onSuggestionsClearRequested={onDriverSuggestionsClearRequested}
            getSuggestionValue={getDriverSuggestionValue}
            renderSuggestion={renderDriverSuggestion}
            onSuggestionSelected={driverAutocompleteSlectedHandle}
            shouldRenderSuggestions={() => true}
            inputProps={{
              className: 'form-control form-custom',
              value: fullName || '',
              placeholder: I18n.t('newbooking.Search_here'),
              onChange: handleDriverChanged,
              disabled: disabled || isCorpBoard,
            }}
          />
          {checkShowDetachBtn() && !isCorpBoard && (
            <Button
              className="add-on-button btn-header text-add-header"
              onClick={props.detachDriver}
            >
              <Translate
                value={'operationSetting.Unassign'}
                className="collapse-filter-title"
                style={{ fontWeight: 500 }}
              />
            </Button>
          )}
        </div>
        <p className="searchTitle" style={{ marginTop: '20px' }}>
          {I18n.t('General.Vehicle')}
        </p>
        <div className="add-on-input-group">
          <VehicleAutoComplete
            disabled={disabled}
            selectedFleet={auth.selectedFleet}
            supplierCompanies={supplierList}
            settingActions={props.settingActions}
            setVehicleAutoComplete={setVehicleState}
            vehicle={vehicleState}
            isBookNow={pickupTimeState === 'Now'}
            isCue={true}
            drivers={driverSelected?._id ? [driverSelected] : driverBooks}
            vehicleTypes={dataMultiBook.vehicleTypes}
            showUnassignBtn={showUnAssignVehicle}
            handleUnassignVehicle={props.detachVehicle}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center btn_group">
        {(showUnAssignVehicle || (checkShowDetachBtn() && !isCorpBoard)) && verifyStatusShowUnassignAllBtn(books) && (
          <Button className="btn-unassign mr-md lightMode" onClick={() => props.handleGroupActionClick(GROUP_ACTIONS.unassignAll)}>
            {I18n.t('cue.unassignAll')}
          </Button>
        )}
        {!auth?.user?.roles?.isSupplier && canOfferDriver && (
          <Button className="btn-save mr-md lightMode" onClick={handleOfferDriver}>
            {I18n.t('bookingdetail.Offer')}
          </Button>
        )}
        <Button className="btn-save lightMode" onClick={handleAssignDriver}>
          {driverBooks.length > 0 || driverSelected?.driverId
            ? I18n.t('bookingdetail.Assign')
            : I18n.t('bookingdetail.Update')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    driverAutocomplete: state.driverAutocomplete,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
    driverAutocompleteActions: bindActionCreators(
      driverAutocompleteActions,
      dispatch
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutosuggestAssignDriver);
