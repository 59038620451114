/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Translate, I18n } from 'react-redux-i18n';
import './newbooking.scss';
import { Col, Row, Button, ButtonToolbar, Modal, Container } from 'react-bootstrap';
import moment from 'moment';
import _, { debounce } from 'lodash';
import currencyFormatter from 'currency-formatter';
import { QQWebservice, QQUltis } from '../../components/qqMap';
import {
  convertDirectionParam,
  getChannelMap,
  findDriverNearest,
  getDirectionMapProviderFromJupiter,
} from '../../utils/mapUtils.js';
import DateMode from '../../components/DateModeBooking';
import RouteInfo from '../../components/RouteInfo';

import * as newbookingActions from '../../actions/newbookingAction';
import * as shuttleAction from '../../actions/shuttleAction';
import * as customerAutocompleteActions from '../../actions/customerAutocompleteAction';
import * as corporateCompanyActions from '../../actions/corporateCompanyAction';
import * as paymentMethodActions from '../../actions/paymentMethodActions';
import * as driverAutocompleteActions from '../../actions/driverAutocompleteActions';
import * as etaFareActions from '../../actions/etaFareActions';
import * as settingActions from '../../actions/settingActions';
import * as flightActions from '../../actions/flightActions';
import * as creditCardActions from '../../actions/creditCardActions';
import * as queuingAreaActions from '../../actions/queuingAreaActions';
import * as customerActions from '../../actions/customerAction';
import * as messageAction from '../../actions/messageAction';
import * as uploadActions from '../../actions/uploadActions';
import { socketConfigs } from '../../constants/socketConfigs';
import { socketDispatchApi } from '../../utils/socketUtils';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle'
import Notes from '../../components/bookingDetail/bookComponent/Notes'
import {
  CCLiteCommonFunc,
  Validation,
  trimPhoneNumber,
  checkDropOffCountry,
  regexpDriverAutoComplete,
  checkMultiPuDoNotEmpty,
  filterCompanyList,
  convertTravelerTypeToText,
  checkForceRateType,
  checkForceCarTypeSelecting,
  filterCarTypeWithoutAssignRate,
  filterTransportCarTypes,
  checkCompanyHasSupportPayment,
  getCompanyOfOperator,
  getSupplierInfoFromID,
  handleMultiSelectCompanyAll,
  checkCanRemoveLastOptionCorporate,
  checkAllSupplierHasSupportPickup,
  checkResetSupplierByZone,
  getOutStandingCustomer,
  checkResetCarWhenGetNewService,
  checkGateWayAddCardFromURL,
  convertDataWhenChangeSupplier,
  roundOff
} from '../../utils/commonFunctions';
import {
  paymentGetwayDisabledNewBooking,
  userType,
  locationType,
  travelerType,
  paymentMethodNumber,
  NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD,
  SUPPLIER_TYPE,
  BOOK_TYPE_FEE,
  NAME_GATEWAY,
  PROVIDER_MAP_TYPE
} from '../../constants/commondata';
import * as loadingActions from '../../actions/loadingBarActions';
import * as corporateActions from '../../actions/corporateAction';
import * as tencentMapActions from '../../actions/tencentMapActions';
import * as bookingDetailActions from '../../actions/bookingDetailAction';
import {
  BookingMapChauffeur,
  BookingInfoChauffeur,
  DispatchInfoChauffeur,
  FlightInfo,
  TravellerInfo,
  PromoInfo,
  DriverCalendarInfo,
  PaymentInfo,
  TripEstimate,
  ExtraServiceInfo
} from '../../components/bookingDetail';

import {
  onChangePickUpHour,
  onChangePickUpMin,
  checkResetDrvWhenChangeCompany,
  calculateFareWithTipChange,
  calculateFareWithPromoChange,
  calculateFareWithServiceFeeChange,
  checkResetVehicleWhenChangeCompany,
  getVehicleWhenChangeDriver,
  checkRequiredDispatch,
  getLocationWhenDrapEnd,
  checkRoundTrip,
  compareLocations,
  checkLocationHasChange,
  filterPointEmpty,
  resetVehicleWhenChangeDispatchType,
  getMsgCreateUpdateBook,
  getAllPointNotEmpty
} from '../../components/bookingDetail/bookFunction/bookingInfo';
import {
  newBookingEtaFareMultiCarCalculator,
  getTravelerModeByCarType,
  isCanAssignOfflineDriver,
  handleFinalServicesRequest,
  resetService
} from '../../components/bookingDetail/bookFunction/serviceRequest';
import { getDefaultTip } from '../../components/bookingDetail/bookFunction/tipPromo';
import { checkPaymentShow } from '../../components/bookingDetail/bookFunction/payment';
import { calculationAdditionalService } from '../../components/bookingDetail/bookFunction/additionalService';
import IframeAddCard from '../../components/IframeAddCard'
import tzlookup from '../../components/tzlookup/tz-lookup';
import PassengerLuggageNumber from '../../components/bookingDetail/bookComponent/PassengerLuggageNumber';
import Confirm from '../../components/confirm/Confirm';
import { getTimezone } from '../../actions/mapProviderAction'
import TrailNotesNewBooking from '../../components/bookingDetail/bookComponent/trailNotes/trailNoteNewBooking.js';
require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

class NewBooking extends Component {
  constructor(props) {
    super();
    this.state = {
      resetCounterVehicleTextSearch: 0,
      data: {
        note: '',
        operatorNote: '',
        instructions: {
          content: '',
          images: [],
        },
        phone: '',
        firstname: '',
        lastname: '',
        email: '',
        outStanding: [],
        traveler_type:
          props.auth.user.userType == userType.FleetAdmin ||
            props.auth.user.userType == userType.FleetUser
            ? travelerType.Individual
            : travelerType.Corporate,
        dispatch_type: 0,
        duration: false,
        carType: '',
        tips: 0,
        driver: '',
        time: 'Now',
        zipCodeFrom: '',
        zipCodeTo: '',
        roundTrip: false,
        rideSharing: false,
        duration: false,
        airline: '',
        flightNumber: '',
        isCustomerVip: false,
        dispatch3rd: props.auth.selectedFleet.partyDispatching || false,
        paymentMethod: 1,
        extraServices: [],
        paxNumber: 1,
        luggageNumber: 0,
        isNewSettingAdditionFee: false,
        minutesToArrive: {
          value: 0,
          expected: 'samePickup'
        }
      },
      phoneStatus: true,
      customer: {
        credits: [],
      },
      isChina: _.get(props.commonData, 'location.isChina', false),
      cartypeSelected: null,
      addressPickUp: '',
      addressDestination: '',
      locationPickUp: null,
      locationDestination: '',
      addressDetailsPickUp: {},
      addressDetailsDestination: {},
      geocodeResultsPickUp: null,
      loadingPickUp: false,
      loadingDestination: false,
      center: null,
      markerPickUp: null,
      markerDestination: null,
      suggestions: [],
      driversuggestions: [],
      driver: {},
      promoAplied: false,
      isShowCancelMessage: false,
      etaFare: null,
      flightSuggestions: [],
      flightScheduleSuggestions: [],
      valid: {},
      isSubmited: false,
      mapDefaultBound: null,
      optionAutocomplete: {},
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null,
      pickupFrom: null,
      destinationFrom: null,
      supplierCompanies: [],
      destinationRecentThirdParty: false,
      isCheckSupportLocation: false,
      dateMode: 'single',
      weekDays: {
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
      },
      puPoints: [{ order: 1, id: uuidv4({ msecs: new Date().getTime() }), hasLocation: false }],
      doPoints: [{ order: 2, id: uuidv4({ msecs: new Date().getTime() }), hasLocation: false }],
      fromDate: '',
      toDate: '',
      recurringTime: '',
      recurringDays: [],
      hasSetDoPoint: false,
      listDirtyLocation: [],
      companyCorporate: [],
      supplierVehicle: '',
      supplierId: '',
      trailNotes: [],
    };
    // this.handleSelectRecentPickup = this.handleSelectRecentPickup.bind(this);
    // this.handleSelectRecentDestination = this.handleSelectRecentDestination.bind(this);
    this.onChangePickUpHour = onChangePickUpHour.bind(this);
    this.onChangePickUpMin = onChangePickUpMin.bind(this);
    this.etaFareCalculator = newBookingEtaFareMultiCarCalculator.bind(this);
    this.debounceEtaFareCalculator = debounce(newBookingEtaFareMultiCarCalculator.bind(this), 300)
    this.getDefaultTip = getDefaultTip.bind(this);
    this.onPickupTimeFocus = this.onPickupTimeFocus.bind(this);
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handleChangeETAFare = this.handleChangeETAFare.bind(this);
    this.handleChangeMarkupPrice = this.handleChangeMarkupPrice.bind(this);
    this.checkLocationHasChange = checkLocationHasChange.bind(this);
  }

  handleAddTrailNotes = (data) => {
    let currentTrailNotes = [...this.state.trailNotes]
    if (currentTrailNotes?.length === 20) return this.context.notification('error', I18n.t('trailNotes.maxComments'))
    const newTrailNote = {
        availability: {
          operator: data.operator,
          driver: data.driver,
          passenger: data.passenger,
        },
        text: data.text,
        files: data.files,
        createdBy: {
          firstName: this.props.auth.user.firstName,
          lastName: this.props.auth.user.lastName,
          userName: this.props.auth.user.userName,
          _id: this.props.auth.user._id
        },
    }
    currentTrailNotes = [newTrailNote, ...currentTrailNotes]
    this.setState({
      trailNotes: currentTrailNotes
    });
  }

  handleDeleteTrailNotes = (index) => {
    const currentTrailNotes = [...this.state.trailNotes]
    currentTrailNotes.splice(index, 1)
    this.setState({
      trailNotes: currentTrailNotes
    });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.isShuttle) {
      if (this.state.data.time === 'Now') {
        this.setState({
          data: {
            ...this.state.data,
            time: moment(),
            dispatch_type: 1,
            dispatch3rd: false,
            paymentMethod: 2,
          },
        })
      }
      if (!this.state.hasSetDoPoint) {
        if (this.state.doPoints && this.state.doPoints.length == 0) {
          this.setState({
            doPoints: [{ order: 2, id: new Date().getTime(), hasLocation: false }],
            hasSetDoPoint: true
          })
        }
      }
    }
  }

  UNSAFE_componentWillMount () {
    let { auth } = this.props;
    let moduleSettings = _.get(auth, 'selectedFleet.moduleSettings');
    const reservation = _.get(auth, 'selectedFleet.generalSetting.reservation');
    if (reservation) {
      this.state.data.minutesToArrive = {
        value: reservation.minutesToArrive,
        expected: reservation.policy === 'samePickup' ? 'samePickup' : reservation.expected
      }
    }
    let center = null;
    let bound = null;
    let centerLocation = ''
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      let latLng = new window.qq.maps.LatLngBounds();
      let defaultMap = (this.props.commonData.mapZone || []).filter(
        (m) => m.isDefault
      )[0];
      defaultMap =
        defaultMap ||
        (this.props.commonData &&
          this.props.commonData.mapZone &&
          this.props.commonData.mapZone[0]);
      if (defaultMap) {
        defaultMap.geo.coordinates.map((cor) => {
          cor.map((z) => {
            latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
          });
        });
      }
      center = latLng.getCenter();
      centerLocation = `${center.lat},${center.lng}`
    } else {
      let latLng = new google.maps.LatLngBounds();
      let defaultMap = (this.props.commonData.mapZone || []).filter(
        (m) => m.isDefault
      )[0];
      defaultMap =
        defaultMap ||
        (this.props.commonData &&
          this.props.commonData.mapZone &&
          this.props.commonData.mapZone[0]);
      if (defaultMap) {
        defaultMap.geo.coordinates.map((cor) => {
          cor.map((z) => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]));
          });
        });
      }
      center = latLng.getCenter();
      bound = null;
      if ((this.props.commonData.mapZone || []).filter((m) => m.isDefault)[0]) {
        bound = new google.maps.Circle({
          radius: 100000,
          center,
        }).getBounds();
      }
      centerLocation = `${center.lat()},${center.lng()}`
    }

    if (
      this.props.auth.user.userType == userType.FleetAdmin ||
      this.props.auth.user.userType == userType.FleetUser
    ) {
      if (moduleSettings.airlineBusiness) {
        this.props.corporateCompanyActions.corporateCompany(
          this.props.auth.selectedFleet.fleetId,
          true
        );
      } else if (moduleSettings.corporate) {
        this.props.corporateCompanyActions.corporateCompany(
          this.props.auth.selectedFleet.fleetId,
          false
        );
      }
    }

    const companyIdOfOperator =  getCompanyOfOperator(this.props.auth, [...this.props.commonData.companies, ...this.props.commonData.suppliers])?._id

    this.setState({
      mapDefaultBound: bound,
      optionAutocomplete: {
        radius: '10000',
        location: centerLocation,
        fleetId: this.props.auth.selectedFleet.fleetId
      },
      data: { ...this.state.data },
      center,
      // supplierCompanies: companyIdOfOperator ? [companyIdOfOperator] : [],
      supplierCompanies: [],
      broadcastBooking: this.props.auth?.selectedFleet?.process?.broadcastBooking || true,
      companyIdOfOperator: companyIdOfOperator,
      gateWayName: this.props.auth.selectedFleet?.creditConfig?.configGateway?.gateway
    });
    this.props.paymentMethodActions.paymentMethod(
      this.props.auth.selectedFleet.fleetId
    );
  }

  parseExistedBookingToNewBooking = (data) => {
    let bookingOj = {
      note: data.booking.request.note,
      operatorNote: data.booking.request.operatorNote,
      phone: data.booking.psgInfo.phone,
      firstname: data.booking.psgInfo.firstName || '',
      lastname: data.booking.psgInfo.lastName || '',
      email: data.booking.psgInfo.email || '',
      isCustomerVip: data.booking.psgInfo.rank === 1,
      traveler_type: data.booking.travelerType || travelerType.Individual,
      dispatch_type: 0,
      isHydraBooking: data.isHydraBooking,
      duration: false,
      carType: data.booking.request.vehicleTypeRequest,
      tips: data.booking.request.tip,
      driver: '',
      time:
        data.booking.request.pickUpTime == 'Now'
          ? 'Now'
          : data.booking.request.pickUpTime ||
          moment.tz(
            data.booking.request.pickUpTimee,
            data.booking.request.pickup.timezone
          ),
      duration: !!(
        data.booking.request.typeRate == 1 || data.booking.request.type == 3
      ),
      rideSharing: data.booking.request.rideSharing,
      packageRateId: data.booking.request.packageRateId,
    };
    this.state.data.dispatch3rd = !!data.booking.dispatch3rd;

    if (data.booking.request.typeRate == 2 || data.booking.request.type == 4) {
      this.state.data.roundTrip = true;
    }

    if (data.isShuttle) {
      this.setState({ isShuttle: true })
    }

    if (data.customer && data.customer.phone !== 'No Phone') {
      this.state.customer = data.customer;
      bookingOj = {
        ...bookingOj,
        firstname: data.customer.firstName,
        lastname: data.customer.lastName,
        phone:
          data.customer.phone === data.customer.userId
            ? 'No Phone'
            : data.customer.phone,
        email: data.customer.email,
      };
      if (
        data.booking.request.moreInfo &&
        data.booking.request.moreInfo.flightInfo
      ) {
        bookingOj.flightNumber =
          data.booking.request.moreInfo.flightInfo.flightNumber;
        bookingOj.airline = data.booking.request.moreInfo.flightInfo.airline;
        const flightInfoType = _.get(data, 'booking.request.moreInfo.flightInfo.type', 0)
        bookingOj.meetDriver = flightInfoType
        if(flightInfoType === -1) bookingOj.meetDriver = 0
      }
    } else {
      bookingOj = {
        ...bookingOj,
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
      };
    }

    this.state.data.paymentMethod = 1;
    if (data.booking.request.paymentType !== 9 &&
      data.booking.request.paymentType !== 13 &&
      data.booking.request.paymentType !== 21
    ) {
      this.state.data.paymentMethod = data.booking.request.paymentType;
    }
    if (
      data.booking.request.paymentType == paymentMethodNumber.personalCard ||
      data.booking.request.paymentType == paymentMethodNumber.corporateCard
    ) {
      // SL-17711 Need to clear credit token (customer have deleted) when rebooking
      const creditValue = data.credit;
      if (creditValue) {
        const findIndexCredit = this.state.customer.credits.findIndex(
          (x) =>
            x.localToken === creditValue.localToken &&
            x.crossToken === creditValue.crossToken
        );
        if (findIndexCredit !== -1) {
          this.state.credit = creditValue;
        }
        if (data.company && data.company.credits) {
          const findIndexCreditCompany = data.company.credits.findIndex(
            (x) =>
              x.localToken === creditValue.localToken &&
              x.crossToken === creditValue.crossToken
          );
          if (findIndexCreditCompany !== -1) {
            this.state.credit = creditValue;
          }
        }
      }
    }
    if (
      data.booking.pricingType != 1 &&
      (data.booking.request.paymentType == paymentMethodNumber.corporateCard ||
        data.booking.request.paymentType == 5 ||
        data.booking.request.paymentType == 7) &&
      data.booking.corporateInfo
    ) {
      this.state.data.clientCaseMatter =
        data.booking.corporateInfo.clientCaseMatter;
      this.state.data.chargeCode = data.booking.corporateInfo.chargeCode;
    }
    if (data.company) {
      this.state.company = data.company;
    }

    if (data.booking.travelerType == 1 && data.booking.corporateInfo) {
      bookingOj.company = data.booking.corporateInfo.corporateId;
      this.state.customer.corporateSelectedInfo =
        this.state.customer.corporateInfo = data.booking.corporateInfo;
    }
    this.state.locationPickUp = {
      lat: data.booking.request.pickup.geo[1],
      lng: data.booking.request.pickup.geo[0],
      zipCode: data.booking.request.pickup.zipCode,
      city: data.booking.request.pickup.city || '',
      countryCode: data.booking.request.pickup.country || '',
      timezone: data.booking.request.pickup.timezone,
      from: data.booking.request.pickup.from,
      address: data.booking.request.pickup.address,
      cityName: data.booking.request.pickup.cityName,
    };
    this.state.addressPickUp = data.booking.request.pickup.address;
    this.state.pickupFrom = data.booking.request.pickup.from;
    this.state.center = this.state.locationPickUp;
    this.state.addressDetailsPickUp =
      data.booking.request.pickup.addressDetails;

    if (
      data.booking.request.destination &&
      data.booking.request.destination.address.length > 0
    ) {
      this.state.locationDestination = {
        lat: data.booking.request.destination.geo[1],
        lng: data.booking.request.destination.geo[0],
        zipCode: data.booking.request.destination.zipCode,
        cityName: data.booking.request.destination.cityName,
        city: data.booking.request.destination.city || '',
        countryCode: data.booking.request.destination.country || '',
        timezone: data.booking.request.destination.timezone,
        from: data.booking.request.destination.from,
        address: data.booking.request.destination.address,
      };
      this.state.addressDestination = data.booking.request.destination.address;
      this.state.destinationFrom = data.booking.request.destination.from;
      this.state.addressDetailsDestination =
        data.booking.request.destination.addressDetails;

      if (data.booking.request.extraDestination.length > 0) {
        this.state.isShowedExtraDestination = true;
        this.state.extraDestination = {
          address: data.booking.request.destination.address,
          lat: data.booking.request.destination.geo[1],
          lng: data.booking.request.destination.geo[0],
          zipCode: data.booking.request.destination.zipCode,
          from: data.booking.request.destination.from,
          city: '',
        };
        this.state.locationDestination = {
          address: data.booking.request.extraDestination[0].address,
          lat: data.booking.request.extraDestination[0].geo[1],
          lng: data.booking.request.extraDestination[0].geo[0],
          zipCode: data.booking.request.extraDestination[0].zipCode,
          from: data.booking.request.extraDestination[0].from,
          cityName: data.booking.request.extraDestination[0].cityName,
          city: '',
        };
        this.state.addressDestination =
          data.booking.request.extraDestination[0].address;
        this.state.destinationFrom =
          data.booking.request.extraDestination[0].from;
      }
    }
    if (
      this.state.locationPickUp &&
      this.state.locationPickUp.address.length > 0 &&
      (!this.state.locationPickUp.from ||
        this.state.locationPickUp.from == locationType.tencent)
    ) {
      this.state.pickupFrom = locationType.google;
    }
    if (
      this.state.locationDestination &&
      this.state.locationDestination.address.length > 0 &&
      (!this.state.locationDestination.from ||
        this.state.locationDestination.from == locationType.tencent)
    ) {
      this.state.destinationFrom = locationType.google;
    }
    if (
      this.state.extraDestination &&
      this.state.extraDestination.address.length > 0 &&
      (!this.state.extraDestination.from ||
        this.state.extraDestination.from == locationType.tencent)
    ) {
      this.state.extraDestination.from = locationType.google;
    }
    if (data.cartypeSelected) {
      this.state.cartypeSelected = data.cartypeSelected;
    }

    const supplierCompanies = data.booking?.request?.supplierCompanies || []
    let puPoints = (data.booking && data.booking.puPoints) || [];
    let doPoints = (data.booking && data.booking.doPoints) || [];

    this.setState(
      {
        data: Object.assign(this.state.data, bookingOj),
        supplierId: data.supplierId,
        supplierVehicle: data.supplierId,
        isHydraBooking: data.isHydraBooking,
        supplierCompanies: this.state.companyIdOfOperator ? [] : supplierCompanies,
        puPoints: this.addMoreKeyForPoint(puPoints),
        doPoints: this.addMoreKeyForPoint(doPoints),
        cartypeSelected: data.cartypeSelected,
      },
      () => {
        this.checkZoneSupportedWithPointComponent({
          fleetId: this.props.auth.selectedFleet.fleetId,
          lat: this.state.locationPickUp.lat,
          lng: this.state.locationPickUp.lng
        })
        .then((data) => {
          const res = data.res || {}
          this.setState({
            supportHydraBooking: res.crossZone || false,
            supportLocalBooking: res.valid || false,
          })
        })
        this.carTypeBaseLocation().then((service) => {
          if (service.ok && service.res) {
            let vehicleType = service.res.vehicleType || []
            if (this.props.isShuttle) {
              vehicleType = vehicleType.filter((ob) => (ob.shuttle))
            }
            if (vehicleType.length > 0) {
              this.carTypeBaseLocationDataPaser(
                service,
                data.booking.request.vehicleTypeRequest
              );
            }
          }
        });
        this.getDirectionAndDraw();
        this.checkOutStandingCustomer();
      }
    );
  };

  setSupplier = (e) => {
    const supplierId = e.target.value != -1 ? e.target.value : ""
    this.setState({
      supplierId: supplierId
    }, () => {
      let cartypeSelected = null;
      let vehicleType = _.get(this.props.newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited') || []
      if (vehicleType.length > 0) {
        if(supplierId) {
          cartypeSelected = vehicleType.find(
            (car) =>
              (!car.dispatchRideSharing &&
              !car.availability || car.availability.cc)
              && (car.providerOfVhc.fleetId === this.state.supplierId)
          );
        } else {
          cartypeSelected = vehicleType[0]
        }
      }
      if (cartypeSelected != null) {
        // update vehicle type
        const providerId = _.get(cartypeSelected, 'providers[0].fleetId', '')
        this.setState(
          {
            cartypeSelected,
            supplierVehicle: providerId 
          },
          () => {
            this.carTypeBaseLocation().then((res) => {
            this.updateExtraService(res);
              this.etaFareCalculator();
            });
            // this.checkNearestDriver();
          }
        );
      } else {
        this.setState(
          {
            cartypeSelected,
          },
          () => {
            // this.checkNearestDriver();
          }
        );
      }
    })
  }

  setMinutesToArrive = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        minutesToArrive: data
      }
    })
  }

  addMoreKeyForPoint = (pointList = []) => {
    if (pointList.length > 0) {
      return pointList.map((point) => {
        if (point.address && point.address.geo) {
          return {
            ...point,
            address: {
              ...point.address,
              lat: point.address.geo[1],
              lng: point.address.geo[0]
            }
          }
        }
        return point
      })
    }
    return pointList
  }

  setDirtyLocation = (newData) => {
    this.setState({ listDirtyLocation: newData })
  }
  componentDidMount () {
    this.state.data.tips = this.getDefaultTip();
    if (this.props.newBooking.bookingData) {
      if (_.isEmpty(this.props.newBooking.bookingData.booking)) {
        this.newBookingFromCallcenterSupport(
          this.props.newBooking.bookingData.customer.phone
        );
      } else {
        this.parseExistedBookingToNewBooking(this.props.newBooking.bookingData);
      }
    }
    if (this.state.data.traveler_type == travelerType.Corporate || this.state.data.traveler_type == travelerType.Airline) {
      if (!this.state.customer) {
        this.state.customer = {};
      }
      if (!this.state.customer.corporateInfo) {
        this.state.customer.corporateSelectedInfo =
          this.state.customer.corporateInfo = {};
      }
    }

    if(this.props.auth.user.userType == userType.CorporateAdmin ||
      this.props.auth.user.userType == userType.CorporateUser) {
      this.props.corporateActions
        .detailCorporate({
          fleetId: this.props.auth.selectedFleet.fleetId,
          _id: this.props.auth.user.corporateId,
        })
        .then((data) => {
          if (data.ok) {
            let companies = []
            if(_.get(data.res, 'supplier.type', '') === SUPPLIER_TYPE.customized) {
              // list company được setting cho fleet
              const companiesSetting = _.get(this.props.commonData, 'companies', [])
              // Company đầu tiên được custome cho corp
              const firstCompanyAssigned = _.get(data.res, 'supplier.value[0]', '')
              const companyState = companiesSetting.find(c => c._id === firstCompanyAssigned)
              if(!_.isEmpty(companyState)) companies = [companyState]
            }
            this.setState({
              company: data.res,
              data: {
                ...this.state.data,
                company: this.props.auth.user.corporateId
              },
              supplierCompanies: companies[0]?._id ? [companies[0]?._id] : []
            })
          }
        });
    }

    // create booking from URL
    const { 
      location = {}, 
      auth = {},
      router
    } = this.props
    if(location.pathname === '/newbooking' && location.query && location.query.phone) {
      this.props.customerActions
        .findOneCustomer({
          fleetId: auth.selectedFleet.fleetId,
          phone: `+${location.query.phone}`
        })
        .then(data => {
          if (data.ok && data.res) {
            this.customerAutocompleteSlectedHandle(null, {suggestion: data.res})
          } else {
            this.setState({
                data: {
                  ...this.state.data,
                  phone: `+${location.query.phone}`
                }
            })
          }
        });
    }
    this.onSocketDispatch();
  }

  handleResultCreateBooking = (data) => {
    if (data.code) {
      this.props.loadingActions.hideLoadingSpiner();
      if(this.state.dateMode === 'recurring') {
        this.context.notification(
          'success',
          I18n.t('messages.booking.Create_new_recurring_booking_success')
        );
      } else {
        this.context.notification(
          'success',
          I18n.t('messages.booking.Create_new_booking_success') + ' #' + data.info.bookId
        );
      }
      this.setState({ preAuthBookId: null });
      const { location, router } = this.props
      if(location && location.pathname === '/newbooking' && router) {
        router.push({
          pathname: `/cue`
        })
      }
      this.closeBookingForm()
    } else {
      // verify card pre-auth
      const preAuthCode = _.get(data, 'info.preAuthorizedCode');
      // Stripe
      if (preAuthCode === 113) {
        this.setState({ clientSecret: data.info.clientSecret });
        return this.verifyStripeSCA(data.info.clientSecret);
      }
      // Payway
      if (preAuthCode === 528 &&
        data.info?.deepLinkUrl
      ) {
        this.setState({ 
          isShowIframePreAuthModal: true,
          urlIframePreAuth: data.info?.deepLinkUrl 
        });
        if(this.state.gateWayName === 'ECPay') {
          window.open(data.info?.deepLinkUrl, '_blank').focus();
        }
        this.props.loadingActions.hideLoadingSpiner();
        return 
      }
      this.props.loadingActions.hideLoadingSpiner();
      const errMgs = this.showMessageErrorBooking(data);
      if (errMgs) {
        this.context.notification('error', errMgs);
      }
    }
  }

  closeModalIframePreAuthModal = () => {
    this.setState({
      isShowIframePreAuthModal: false
    })
  }

  onSocketDispatch = (data) => {
    socketDispatchApi.remove(socketConfigs.receive.booking.preauthResult);
    socketDispatchApi.on(
      socketConfigs.receive.booking.preauthResult,
      (data) => {
        const { clientSecret } = this.state;
        const gateWayName = this.props.auth.selectedFleet?.creditConfig?.configGateway?.gateway

        if(checkGateWayAddCardFromURL(gateWayName)) {
          if (data.code) {
            this.props.loadingActions.hideLoadingSpiner();
            this.context.notification(
              'success',
              I18n.t('messages.booking.Create_new_booking_success')
            );
            this.setState({ preAuthBookId: null });
            this.closeBookingForm()
          } else {
            if (data.info.preAuthorizedCode !== 911) {
              this.context.notification(
                'error',
                this.showMessageErrorBooking(data)
              );
            }
          }
          return
        }

        if (
          !clientSecret ||
          !data.clientSecret ||
          clientSecret !== data.clientSecret
        ) {
          return;
        }

        if (data.code) {
          this.props.loadingActions.hideLoadingSpiner();
          this.context.notification(
            'success',
            I18n.t('messages.booking.Create_new_booking_success')
          );
          this.setState({ preAuthBookId: null });
          this.closeBookingForm()
        } else {
          // verify card pre-auth
          const preAuthCode = _.get(data, 'info.preAuthorizedCode');
          if (preAuthCode === 113) {
            return this.verifyStripeSCA(data.info.clientSecret);
          }
          this.props.loadingActions.hideLoadingSpiner();
          if (data.info.preAuthorizedCode !== 911) {
            this.context.notification(
              'error',
              this.showMessageErrorBooking(data)
            );
          }
        }
      }
    );
  };

  verifyStripeSCA = (clientSecret) => {
    const { auth } = this.props;
    const stripe = window.Stripe(auth.selectedFleet.stripePublicKey);
    stripe.handleCardPayment(clientSecret).then((result) => {
      if (result.error) {
        // Display error.message in your UI.
        this.props.loadingActions.hideLoadingSpiner();
        const errorMessage = result.error.message || result.error.code;
        this.context.notification('error', errorMessage);
      }
    });
  };

  showMessageErrorBooking = (data) => {
    let msg = (
      <Translate value="messages.booking.data_incorect" dangerousHTML />
    );

    const msgCommon = getMsgCreateUpdateBook(data)
    if(msgCommon) return msgCommon

    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0;
          let min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
      } else if (dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      console.log(data.message);
      const dataInfo = data.info || data.error || {};
      if(data.errorCode === 'CANNOT_FARM_OUT') {
        msg = "This booking can't be farm out: no permission"
        return msg
      }
      if (dataInfo?.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (dataInfo.sameZone === false && dataInfo.crossZone === false) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
        if (dataInfo.rateErrorCode) {
          switch (dataInfo.rateErrorCode) {
            case 306:
              msg = I18n.t('messages.booking.306');
              break;
            case 407:
              msg = I18n.t('messages.booking.407');
              break;
            default:
              break;
          }
        }
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
        // if(data.message) {
        //   msg = data.message
        // }
      } else if (!dataInfo.recurringTimeRange) {
        // msg = I18n.t('messages.booking.exDoDiffDo');
        let maximumPeriodRecurringOrdersFleet =
          dataInfo.maximumPeriodRecurringOrdersFleet;
        msg = I18n.t('messages.booking.recurringTimeRange').format(
          maximumPeriodRecurringOrdersFleet
        );
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        this.showConfirmForceOverlap();
        msg = null;
      } else if (dataInfo.promo) {
        switch (dataInfo.promo) {
          case 10:
            msg = I18n.t('messages.promoMsg.430');
            break;
          case 11:
            msg = I18n.t('messages.promoMsg.431');
            break;
          case 12:
            msg = I18n.t('messages.promoMsg.432');
            break;
          case 13:
            msg = I18n.t('messages.promoMsg.433');
            break;
          case 7:
            msg = I18n.t('messages.promoMsg.434');
            break;
          default:
            break;
        }
      }
    }
    return msg;
  };

  checkOutStandingCustomer = async () => {
    const { customer, data } = this.state;
    const { selectedFleet } = this.props.auth;
    const result = await getOutStandingCustomer(customer?.userId, selectedFleet, this.props.customerActions.getPendingPaymentCustomer)
    this.setState({
      data: {
        ...data,
        outStanding: result.filter(
          (o) => o.amount > 0
        )
      }
    })
  };

  newBookingFromCallcenterSupport (phone) {
    this.state.data.phone = phone;
    this.handleBlurPhone(true, phone, null, phone);
  }

  getDefaultZone = () => {
    if (
      this.props.commonData.mapZone &&
      this.props.commonData.mapZone.length > 0
    ) {
      const defaultMap = this.props.commonData.mapZone.filter(
        (m) => m.isDefault
      )[0];
      return defaultMap;
    }
    return null;
  };

  checkNearestDriver = () => {
    const { 
      newBooking = {}, 
      newBooking: {
        locationService = {}
      }
    } = this.props
    const vehicleType = locationService && locationService.vehicleType || []
    let vehicleIdAvailable = filterTransportCarTypes(vehicleType)
    vehicleIdAvailable = vehicleIdAvailable.map((vhc) => {
        return vhc._id
    })
    if (
      !this.state.locationPickUp ||
      !this.state.cartypeSelected ||
      this.state.data.time != 'Now' ||
      !newBooking.locationService
    ) {
      this.setState({ nearestDriver: null });
      return;
    }
    let object = {};
    if (newBooking.locationService.crossZone) {
      object = {
        fleetSupport: newBooking.locationService.fleetSupport,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        status: 'bookIn',
        typeIds: [this.state.cartypeSelected._id],
        zoneIds: newBooking.locationService.zoneIds,
        fleetId: this.props.auth.selectedFleet.fleetId, supplierCompanies
      };
    } else {
      object = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        status: this.state.data.time == 'Now' ? 'bookIn' : 'online',
        vehicleTypeIds: vehicleIdAvailable ? vehicleIdAvailable : [],
        supplierCompanies: this.state.supplierCompanies
      };
    }
    if (
      (this.state.data.traveler_type == travelerType.Corporate || this.state.data.traveler_type == travelerType.Airline) &&
      this.state.company
    ) {
      object.corporateId = this.state.company._id;
      let supplier = this.state.company && this.state.company.supplier || {}
      if (supplier.type == 'custom') {
        object.limitCompany = supplier.value;
      }
    }
    this.props.newbookingActions
      .checkNearestDriver(
        object,
        this.props.newBooking.locationService.crossZone
      )
      .then((data) => {
        if(data.res) {
          this.handleNearestMultiCartype(data.res)
          return
        }
        this.setState({ nearestDriver: null });
        // if (data.res && data.res.list && data.res.list.length > 0) {
        //   const destinations = data.res.list.map((drv) => {
        //     if (
        //       this.props.commonData.location &&
        //       this.props.commonData.location.isChina
        //     ) {
        //       return new window.qq.maps.LatLng(
        //         drv.loc.coordinates[1],
        //         drv.loc.coordinates[0]
        //       );
        //     }
        //     return new google.maps.LatLng(
        //       drv.loc.coordinates[1],
        //       drv.loc.coordinates[0]
        //     );
        //   });
        //   // if (
        //   //   this.props.commonData.location &&
        //   //   this.props.commonData.location.isChina
        //   // ) {
        //   //   const url = QQWebservice.DistanceMatrix(
        //   //     process.env.REACT_APP_TENCENT_API_KEY,
        //   //     this.state.locationPickUp,
        //   //     destinations,
        //   //     'driving'
        //   //   );
        //   //   this.props.tencentMapActions
        //   //     .TencentMapWebserviceCall(url)
        //   //     .then((data) => {
        //   //       if (data.ok && data.res && data.res.status == 0) {
        //   //         let minValue = 0;
        //   //         for (let j = 0; j < data.res.result.elements.length; j++) {
        //   //           const shortestRound = data.res.result.elements[j];
        //   //           if (minValue == 0 || minValue > shortestRound.duration) {
        //   //             minValue = shortestRound.duration;
        //   //           }
        //   //         }
        //   //         this.setState({
        //   //           nearestDriver: {
        //   //             minText: toHumanFormat(
        //   //               secondsToTime(
        //   //                 Math.floor(
        //   //                   minValue *
        //   //                   (this.state.cartypeSelected.googleETAFactor || 1)
        //   //                 ) + 60
        //   //               )
        //   //             ),
        //   //             closest: Math.floor(
        //   //               minValue *
        //   //               (this.state.cartypeSelected.googleETAFactor || 1)
        //   //             ),
        //   //           },
        //   //         });
        //   //       }
        //   //     });
        //   // } else {
        //   // }
        //   getDistanceMatrix({
        //     origins: [this.state.locationPickUp.lat, this.state.locationPickUp.lng],
        //     destinations: destinations,
        //     vehicleType: this.state.cartypeSelected,
        //     auth: this.props.auth,
        //     callback: this.getDistanceMatrixCallback,
        //     isTencent: this.props.commonData.location.isChina
        //   });
        // } else {
        //   this.setState({ nearestDriver: null });
        // }
      });
  };

  getDistanceMatrixCallback = (response) => {
    this.setState({ nearestDriver: response });
  };

  handleNearestMultiCartype = async (response) => {
    let promiseList = [],
      paramRequest = []
    const vehicleAvailable = _.get(this.props.newBooking, 'locationService.vehicleType', [])
    for (const vehicleId in response) {
      const vehicleItem = response[vehicleId],
        vehicleDetail = vehicleAvailable.find((vhc) => vhc._id === vehicleId);
      const destinations = vehicleItem.list && vehicleItem.list.map((drv) => {
        return {
          lng: drv.loc.coordinates[0],
          lat: drv.loc.coordinates[1],
        }
      });
      if(destinations && destinations.length > 0) {
        paramRequest.push({
          origins: `${this.state.locationPickUp.lat},${this.state.locationPickUp.lng}`,
          destinations: convertDirectionParam(destinations),
          mode: getTravelerModeByCarType(vehicleDetail),
          fleetId: this.props.auth.selectedFleet.fleetId,
          channel: getChannelMap(this.props.auth.selectedFleet.fleetId),
          vehicleDetail: vehicleDetail,
          forceProvider: this.state.isChina ? PROVIDER_MAP_TYPE.tencent : '',
          userId: this.props?.auth?.user?._id
        })
      }
    }

    const nearestFinal = await findDriverNearest(paramRequest, this.state.isChina);
    if(nearestFinal && nearestFinal.length > 0) {
      const vehicleSelecting = nearestFinal.find((vhc) => vhc.vehicleType === this.state?.cartypeSelected?.vehicleType) 
      this.setState({
        nearestDriver: vehicleSelecting,
        nearestDriverList: nearestFinal
      })
    } else {
      this.setState({
        nearestDriver: null,
        nearestDriverList: []
      })
    }

  }
  getDirectionCallback = (response) => {
    this.setState(
      {
        directions: response.overviewPolyline,
        disDur: {
          distance: {
            value: response.distanceValue,
            text: response.distance
          },
          duration: {
            value: response.estimateValue,
            text: response.time
          }
        },
      },
      this.etaFareCalculator
    );
  };

  checkDirtyLocation = () => {
    const { puPoints = [], doPoints = [] } = this.state
    if (
      puPoints.some(ob => _.isEmpty(_.get(ob, 'address.address', '')))
      || doPoints.some(ob => _.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      return true
    }
    return false
  }

  reOrderPointToSubmit = (points = []) => {
    if (points && points.length === 0) return []
    return points.map((ob, index) => {
      return {
        ...ob,
        order: index + 1
      }
    })
  }

  getDirectionAndDraw = async () => {
    const { puPoints = [], doPoints = [] } = this.state
    
    const travelMode = getTravelerModeByCarType(this.state.cartypeSelected, this.props.commonData.location.isChina);
    const points = getAllPointNotEmpty({ puPoints, doPoints, roundTrip: this.state.data?.roundTrip })
    if(points?.length > 0 && this.checkLocationHasChange(points)) {
      getDirectionMapProviderFromJupiter({
        points,
        travelMode,
        unit: this.props.auth.selectedFleet.unitDistance == 'km' ? 0 : 1,
        shortestPathEstimation: this.props.auth.selectedFleet.shortestPathEstimation,
        auth: this.props.auth,
        callback: this.getDirectionCallback,
        isTencent: this.props?.commonData?.location?.isChina,
        vehicleType: this.state?.cartypeSelected?.vehicleType,
      });
    }
    // if (
    //   this.state.locationPickUp && this.state.locationDestination ||
    //   puPointsNotEmpty.length > 0 ||
    //   doPointsNotEmpty.length > 0

    // ) {
    //   puPointsNotEmpty.forEach((point) => {
    //     let geo = point && point.address && point.address.geo || []
    //     if (_.isEmpty(geo)) return
    //     points.push({
    //       lat: geo[1],
    //       lng: geo[0],
    //     });
    //   })
    //   doPointsNotEmpty.forEach((point) => {
    //     let geo = point && point.address && point.address.geo || []
    //     if (_.isEmpty(geo)) return
    //     points.push({
    //       lat: geo[1],
    //       lng: geo[0],
    //     });
    //   })
    //   if (this.state.data.roundTrip && puPointsNotEmpty[0]) {
    //     let geo = puPointsNotEmpty[0] && puPointsNotEmpty[0].address && puPointsNotEmpty[0].address.geo || []
    //     points.push({
    //       lat: geo[1],
    //       lng: geo[0]
    //     });
    //   }
    //   let fleetId = this.props.auth.selectedFleet.fleetId
    //     if(this.state.isHydraBooking) {
    //       fleetId = this.state.supplierVehicle
    //     }
      
    //   if(this.checkLocationHasChange(points)) {
    //     getDirectionMapProviderFromJupiter({
    //       points,
    //       travelMode,
    //       unit: this.props.auth.selectedFleet.unitDistance == 'km' ? 0 : 1,
    //       shortestPathEstimation: this.props.auth.selectedFleet.shortestPathEstimation,
    //       auth: this.props.auth,
    //       callback: this.getDirectionCallback,
    //       isTencent: this.props?.commonData?.location?.isChina,
    //       vehicleType: this.state?.cartypeSelected?.vehicleType,
    //     });
    //   }
    // }
  };

  handleChangePickUp = (addressPickUp) => {
    if (addressPickUp && addressPickUp.length > 0) {
      if (addressPickUp != this.state.addressPickUp) {
        this.resetPackageHourlyOption();
      }
      this.setState({
        addressPickUp,
        geocodeResultsPickUp: null,
        pickupFrom: locationType.google,
      });
    } else {
      this.handleAddressRemovedPickUp();
    }
  };

  handleChangeThirdPartyPickUp = (addressThirdPartyPickUp) => {
    if (addressThirdPartyPickUp && addressThirdPartyPickUp.length > 0) {
      this.setState({
        addressPickUp: addressThirdPartyPickUp,
        geocodeResultsPickUp: null,
        pickupFrom: locationType.thirdParty,
      });
    } else {
      this.handleAddressRemovedPickUp();
    }
  };

  handlePickupDrapEnd = (results, status, id) => {
    getLocationWhenDrapEnd({
      results, 
      id, 
      callback: this.pickupDrapEndCallback, 
      auth: this.props.auth, 
      language: this.props?.language,
      isTencent: this.props?.commonData?.location?.isChina
    })
  };

  pickupDrapEndCallback = (place, location, id) => {
    if (id || (this.state.isChina && location)) {
      this.checkZoneSupportedWithPointComponent({
          fleetId: this.props.auth.selectedFleet.fleetId,
          lat: location.lat,
          lng: location.lng,
        })
        .then((checkData) => {
          const res = checkData.res || {};
          this.dragEditPoint(
            place,
            location,
            id,
            this.state.puPoints,
            {
              supportHydraBooking: res.crossZone || false,
              supportLocalBooking: res.valid || false,
            },
            this.setNewPuPoint
          );
        });
    } else {
      this.setState(
        {
          addressPickUp: place ? place.formatted_address : '',
          locationPickUp: location,
          loadingPickUp: false,
          pickupFrom: locationType.google,
          center: location,
          addressDetailsPickUp: location.addressDetails,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok && data.res) {
              this.carTypeBaseLocationDataPaser(data);
            }
          });
          this.getDirectionAndDraw();
        }
      );
    }
  };

  dragEditPoint = (
    place = {},
    location = {},
    id,
    pointsCurrent,
    serviceSupport = {},
    callback
  ) => {
    let updatedPoint = [...pointsCurrent]
    let pointIndex = updatedPoint.findIndex((obj => obj.id == id));
    location.geo = [location.lng, location.lat]
    location.address = place.formatted_address || ''
    if(this.state.isChina) {
      // neu la china chỉ support 1 point
      pointIndex = 0
      location.from = 'gg'
    }
    updatedPoint[pointIndex].address = location
    updatedPoint[pointIndex].addressPointText = place.formatted_address || ''
    if(!_.isEmpty(serviceSupport)) {
      updatedPoint[pointIndex].supportHydraBooking = serviceSupport.supportHydraBooking
      updatedPoint[pointIndex].supportLocalBooking = serviceSupport.supportLocalBooking
    }
    callback(updatedPoint)
  }

  handleDestinationDrapEnd = (results, status, id) => {
    getLocationWhenDrapEnd({
      results, 
      id, 
      callback: this.destinationDrapEndCallback, 
      auth: this.props.auth, 
      language: this.props?.language,
      isTencent: this.props.commonData?.location?.isChina
    })
  };

  destinationDrapEndCallback = (place, location, id) => {
    this.resetPackageHourlyOption();
    if (id || (this.state.isChina && location)) {
      this.dragEditPoint(place, location, id, this.state.doPoints, {}, this.setNewDoPoint)
    } else {
      this.setState(
        {
          addressDestination: place ? place.formatted_address : '',
          loadingDestination: false,
          destinationFrom: locationType.google,
          locationDestination: location,
          center: location,
          addressDetailsDestination: location.addressDetails,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              if (data.res.crossZone) {
                this.state.data.dispatch3rd = false;
                this.getCrossZoneBaseLocation();
              }
            }
          });
          this.getDirectionAndDraw();
        }
      );
    }
  };

  handleExtraDestinationDrapEnd = (results) => {
    getLocationWhenDrapEnd({
      results, 
      callback: this.extraDestinationDrapEndCallback, 
      auth: this.props.auth,
      language: this.props?.language,
      isTencent: this.props.commonData.location.isChina
    })
  };

  extraDestinationDrapEndCallback = (place, position) => {
    this.setState(
      {
        extraDestination: {
          ...position,
          address: place ? place.formatted_address : '',
          from: locationType.google,
        },
      },
      () => {
        this.carTypeBaseLocation().then((data) => {
          if (data.ok) {
            if (data.res.crossZone) {
              this.state.data.dispatch3rd = false;
              this.getCrossZoneBaseLocation();
            }
          }
        });
        this.getDirectionAndDraw();
      }
    );
  };

  renderGeocodeSuccessPickUp = (lat, lng) => {
    this.setState({
      center: {
        lat,
        lng,
      },
      markerPickUp: {
        lat,
        lng,
        icon: '/map/mk_pickup.png',
      },
    });
  };

  handleAddressRemovedPickUp = () => {
    this.resetPackageHourlyOption();
    this.setState(
      {
        addressPickUp: '',
        markerPickUp: null,
        geocodeResultsPickUp: null,
        locationPickUp: null,
        directions: null,
        pickupFrom: null,
        isCheckSupportLocation: false,
      },
      this.etaFareCalculator
    );
  };

  handleSelectThirdPartyDestination = (addressDestination, data) => {
    if (addressDestination != this.state.addressDestination) {
      this.resetPackageHourlyOption();
    }
    if (addressDestination && addressDestination !== '') {
      if (data && data.location) {
        this.setState(
          {
            center: {
              lat: data.location.coordinates[1],
              lng: data.location.coordinates[0],
            },
            locationDestination: {
              lat: data.location.coordinates[1],
              lng: data.location.coordinates[0],
              zipCode: data.location.zipCode,
              city: data.location.city,
              countryCode: data.location.countryCode,
              instructionLink: data.location.instructionLink,
            },
            destinationFrom: locationType.thirdParty,
            addressDestination,
          },
          () => {
            this.carTypeBaseLocation().then((data) => {
              if (data.ok && data.res) {
                if (data.res.crossZone) {
                  this.state.data.dispatch3rd = false;
                  this.getCrossZoneBaseLocation();
                }
              }
            });
            this.getDirectionAndDraw();
          }
        );
      }
    } else {
      this.setState({
        locationDestination: null,
        destinationFrom: null,
      });
    }
  };

  handleAddExtraDestinationButtunClick = () => {
    if (!this.state.extraDestination) {
      this.state.extraDestination = {};
    }
    this.setState({ isShowedExtraDestination: true });
  };

  handleSelectThirdPartyExtraDestination = (address, data) => {
    this.resetPackageHourlyOption();
    if (address && address !== '') {
      if (data && data.location) {
        this.setState(
          {
            center: {
              lat: data.location.coordinates[1],
              lng: data.location.coordinates[0],
            },
            extraDestination: {
              lat: data.location.coordinates[1],
              lng: data.location.coordinates[0],
              zipCode: data.location.zipCode,
              city: data.location.city,
              from: locationType.thirdParty,
              address,
              instructionLink: data.location.instructionLink,
            },
            data: {
              ...this.state.data,
              duration: false,
              roundTrip: false,
            },
          },
          () => {
            this.carTypeBaseLocation().then((data) => {
              if (data.ok) {
                if (data.res.crossZone) {
                  this.state.data.dispatch3rd = false;
                  this.getCrossZoneBaseLocation();
                }
              }
            });
            this.getDirectionAndDraw();
          }
        );
      }
    } else {
      this.setState({
        extraDestination: null,
      });
    }
  };

  handleChangeThirdPartyExtraDestination = (address) => {
    if (address && address.length > 0) {
      this.state.extraDestination = {
        ...this.state.extraDestination,
        address,
        from: locationType.thirdParty,
      };
      this.setState({ extraDestination: this.state.extraDestination });
    } else {
      this.handleAddressRemovedExtraDestination();
    }
  };

  handleChangeExtraDestination = address => {
    if (address && address.length > 0) {
      this.state.extraDestination = {
        ...this.state.extraDestination,
        address,
        from: locationType.google,
      };
      this.setState({ extraDestination: this.state.extraDestination });
    } else {
      this.handleAddressRemovedExtraDestination();
    }
  };

  handleAddressRemovedExtraDestination = () => {
    this.resetPackageHourlyOption();
    if (this.state.extraDestination) {
      this.setState(
        {
          extraDestination: null,
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              if (data.res.crossZone) {
                this.state.data.dispatch3rd = false;
                this.getCrossZoneBaseLocation();
              }
            }
          });
          this.getDirectionAndDraw();
        }
      );
    }
  };

  handleChangeNote = (e) => {
    this.setState({ data: { ...this.state.data, note: e.target.value } });
  };

  handleChangeWelcomeMessage = (e) => {
    this.setState({ data: { ...this.state.data, welcomeMessage: e.target.value } });
  };

  handleChangeAffiliateNote = (e) => {
    this.setState({ data: { ...this.state.data, affiliateNote: e.target.value } });
  };

  handleChangeNoteSuperHelper = (e) => {
    let instructions = this.state.data.instructions || {};
    instructions.content = e.target.value;
    this.setState({ data: { ...this.state.data, instructions: instructions } });
  };

  handleChangeTip = (e) => {
    this.state.data.tips = e.target.value;
    this.setState({ data: this.state.data }, () => {
      if(this.state.isFareEdited) {
        this.setState({
          etaFare: calculateFareWithTipChange({
            auth: this.props.auth,
            promoInfo: this.state.promo,
            etaFare: this.state.etaFare,
            data: this.state.data,
            tipPercent: this.state?.data?.tips || 0
          })
        })
      } else {
        this.debounceEtaFareCalculator(); 
      }
    })
  };

  handleAdditionalService = (itemId) => {
    const { data, promo } = this.state,
      { auth } = this.props;
    this.setState({
      data: {
        ...data,
        extraServices: calculationAdditionalService(itemId, data)
      }
    }, () => {
      if(this.state.isFareEdited) {
        // this.setState({
        //   etaFare: calculateFareWithServiceFeeChange({
        //     auth,
        //     data,
        //     promoInfo: promo,
        //     etaFare: this.state.etaFare, 
        //   })
        // })
        return;
      } else {
        this.etaFareCalculator()
      }
    })
  }

  handleChangePaxNumber = (value) => {
    // this.state.data.paxNumber = value;
    value = parseInt(value || 0);
    this.setState({ data: { ...this.state.data, paxNumber: value } });
  };

  handleChangeLuggageNumber = (value) => {
    value = parseInt(value || 0);
    this.state.data.luggageNumber = value;
    this.setState({ data: this.state.data });
  };

  handleChangePhone = (status, value, countryData, number, id, suggestion) => {
    this.autoCompleteType = 'phone';
    const newNumber = suggestion ? suggestion.newValue : number;
    this.setState({ data: { ...this.state.data, phone: newNumber, isCustomerVip: this.state?.company?.isCorporateVip || false } });
  };

  reloadCorporateCompany = () => {
    if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      this.props.corporateCompanyActions
        .getCorporateCompany(this.props.auth.selectedFleet.fleetId)
        .then((data) => {
          let company = (data.res && data.res[0]) || {};
          if (company._id) {
            this.setState({ company });
          }
        });
    }
  };

  getTravelerTypeText = () => {
    const travelerType = this.state.data.traveler_type == 0 ? 'individual' : 'corporation';
    const isAirlineCorporate = this.state.company && this.state.company.isAirline || false
    return convertTravelerTypeToText(travelerType, isAirlineCorporate)
  }

  reloadCustomerTSYSGateway = (number) => {
    this.props.customerAutocompleteActions
      .customerAutocomplete({
        fleetId: this.props.auth.selectedFleet.fleetId,
        str: trimPhoneNumber(number).replace(/\D/g, ''),
        travelerType: this.getTravelerTypeText(),
      })
      .then((data) => {
        if (data.res) {
          const suggestions = data.res.list.filter(
            (item) =>
              trimPhoneNumber(item.phone) ===
              trimPhoneNumber(this.state.data.phone)
          );
          if (suggestions[0]) {
            const suggestData = suggestions[0];
            suggestData.phone = number;
            this.customerAutocompleteSlectedHandle(null, {
              suggestion: suggestData,
            });
          } else {
            this.clearCustomerData(number);
          }
        } else {
          this.clearCustomerData(number);
        }
      });
  };

  handleBlurPhone = (
    status,
    value,
    countryData,
    number,
    id,
    suggestion,
    isCountryCodeOnly
  ) => {
    this.state.phoneStatus = status;
    if (isCountryCodeOnly) {
      this.clearCustomerData('');
      return;
    }
    if (suggestion && suggestion.highlightedSuggestion) {
      this.customerAutocompleteSlectedHandle(null, {
        suggestion: suggestion.highlightedSuggestion,
      });
    } else {
      const suggestions = this.state.suggestions.filter(
        (item) =>
          trimPhoneNumber(item.phone) === trimPhoneNumber(this.state.data.phone)
      );
      if (suggestions[0]) {
        this.customerAutocompleteSlectedHandle(null, {
          suggestion: suggestions[0],
        });
      } else {
        this.props.customerAutocompleteActions
          .customerAutocomplete({
            fleetId: this.props.auth.selectedFleet.fleetId,
            str: trimPhoneNumber(number).replace(/\D/g, ''),
            travelerType: this.getTravelerTypeText(),
            corporateId: _.get(this.state.company, '_id', ''),
            airlineId: _.get(this.state.company, '_id', '')
          })
          .then((data) => {
            if (data.res) {
              console.log(data.res)
              const suggestions = data.res.list.filter(
                (item) =>
                  trimPhoneNumber(item.phone) ===
                  trimPhoneNumber(this.state.data.phone)
              );
              if (suggestions[0]) {
                const suggestData = suggestions[0];
                suggestData.phone = number;
                this.customerAutocompleteSlectedHandle(null, {
                  suggestion: suggestData,
                });
              } else {
                this.clearCustomerData(number);
              }
            } else {
              this.clearCustomerData(number);
            }
          });
      }
    }
  };

  clearCustomerData = (number) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          /* Dont clear customer name vs email when number was not saved */
          firstname: this.state.data.firstname ? this.state.data.firstname : '',
          lastname: this.state.data.lastname ? this.state.data.lastname : '',
          phone: number,
          email: '',
          cardHolder: '',
          cardNumber: '',
          expiredDate: '',
          cvv: '',
          city: '',
          state: '',
          cardAddress: '',
          postalCode: '',
          country: '',
          isCustomerVip: this.state?.company?.isCorporateVip || false,
          outStanding: [],
        },
        credit: null,
        customer: {},
      },
      () => {
        this.setState({
          data: {
            ...this.state.data,
            tips: this.getDefaultTip(),
          },
        });
        this.etaFareCalculator();
      }
    );
  };

  handleChangeTravelerType = (e) => {
    if (
      this.props.newBooking &&
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.crossZone
    ) {
      this.state.data.paymentMethod = paymentMethodNumber.personalCard;
    } else if (e.target.value == 1) {
      if (
        !this.props.paymentMethod.corporateTypes.find(
          (card) => card.value == this.state.data.paymentMethod
        )
      ) {
        // this.state.data.paymentMethod = this.props.paymentMethod.corporateTypes[0].value;
        let paymentFirst = this.props.paymentMethod.corporateTypes[0].value;
        this.state.data.paymentMethod = paymentFirst
        if ((paymentFirst == 6 || paymentFirst == 5) && this.props.isShuttle) {
          this.ValidatorCallback('payment_card', true)
        }
      }
    } else if (
      !this.props.paymentMethod.individualTypes.find(
        (card) => card.value == this.state.data.paymentMethod
      )
    ) {
      // this.state.data.paymentMethod = this.props.paymentMethod.individualTypes[0].value;
      let paymentFirst = this.props.paymentMethod.individualTypes[0].value;
      this.state.data.paymentMethod = paymentFirst;
      if ((paymentFirst == 6 || paymentFirst == 5) && this.props.isShuttle) {
        this.ValidatorCallback('payment_card', true)
      }
    }
    
    if (e.target.value == 0) {
      this.state.company = null;
      this.setState(
        {
          data: {
            ...this.state.data,
            traveler_type: e.target.value,
            packageRateId: null,
            company: null,
            isCustomerVip: this.state.data.oldVipCus
          },
          supplierCompanies: this.state.companyIdOfOperator ? [this.state.companyIdOfOperator] : [],
          package: null,
        },
        () => {
          this.carTypeBaseLocation().then(data => {
            this.checkNearestDriver()
            this.carTypeBaseLocationDataPaser(data, null, true);
            if (!this.checkDirtyLocation()) {
              this.etaFareCalculator();
            }
          })
        }
      );
    } else {
      this.state.company =
        this.props.corporateCompany && this.props.corporateCompany.length > 0
          ? this.props.corporateCompany[0]
          : null;
      if (this?.props?.corporateCompany[0]?.isCorporateVip) {
        this.state.data.isCustomerVip = true;
      }
      this.state.customer = this.buildCustomerWithSelectedCompany(
        this.state.customer,
        this.state.company
      );

      let paymentMethod = this.state.data && this.state.data.paymentMethod
      // Set Default Invoicing for Airline Booking
      if (this.state.company && this.state.company.isAirline) {
        paymentMethod = 5
      }

      this.setState(
        {
          data: {
            ...this.state.data,
            traveler_type: e.target.value,
            company: this.state.company ? this.state.company._id : null,
            package: null,
            packageRateId: null,
            // carType: '',
            paymentMethod: paymentMethod
          },
          supplierCompanies: this.state.companyIdOfOperator ? [this.state.companyIdOfOperator] : [],
          package: null,
        },
        () => {
          this.carTypeBaseLocation().then(data => {
            this.checkNearestDriver()
            this.carTypeBaseLocationDataPaser(data, null, true);
            if (!this.checkDirtyLocation()) {
              this.etaFareCalculator();
            }
          })
        }
      );
    }
  };

  handleChangeDispatchType = (e) => {
    const { newBooking } = this.props
    const newDispatchType = parseInt(e.target.value)
    let { cartypeSelected, isHydraBooking, supplierVehicle } = this.state
    let isETA = false
    // this.state.data.dispatch_type = parseInt(e.target.value);
    if (newDispatchType == 0) {
      this.state.data.driver = '';
    }
    if(newDispatchType === -1) {
      // set cartype hydra đầu tiên trong list
      const vehicleTypeHydraSplited = _.get(newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited') || []
      isHydraBooking = true
      if(supplierVehicle) {
        cartypeSelected = vehicleTypeHydraSplited.find(car => car.providerId.fleetId === supplierVehicle)
      } else {
        cartypeSelected = vehicleTypeHydraSplited[0] || {}
        supplierVehicle = _.get(vehicleTypeHydraSplited[0], 'providerOfVhc.fleetId', '')
      }
      isETA = true
    } else {
      // Đang ở hydra mà chuyển về  transport thì set cartype đầu tiên của transport
      if(this.state.isHydraBooking) {
        const vehicleTypeTransport = _.get(newBooking, 'locationService.vehicleType') || []
        cartypeSelected = vehicleTypeTransport[0] || {}
        supplierVehicle = ''
        isETA = true
      }
      isHydraBooking = false
    }
    this.setState({
        cartypeSelected: cartypeSelected,
        isHydraBooking: isHydraBooking,
        supplierVehicle: supplierVehicle,
        data: { 
          ...this.state.data, 
          dispatch_type: newDispatchType,
          vehicle: resetVehicleWhenChangeDispatchType({
            pickupTime: this.state.data.time,
            currentVehicle: this.state?.data?.vehicle
          })
        }
      },
      () => {
        if(isETA) {
          this.getDirectionAndDraw((response) => {
            this.setState(
              {
                disDur: {
                  distance: {
                    value: response.distanceValue,
                    text: response.distance
                  },
                  duration: {
                    value: response.estimateValue,
                    text: response.time
                  }
                },
              }
            )
          })
        
        }
      }
    );
  };

  handleChangeFirstName = (event, { newValue }) => {
    this.autoCompleteType = 'firstname';
    this.setState({ data: { ...this.state.data, firstname: newValue } });
  };

  handleChangeLastName = (e, { newValue }) => {
    this.autoCompleteType = 'lastname';
    this.setState({ data: { ...this.state.data, lastname: newValue } });
  };

  handleChangeEmail = (e, { newValue }) => {
    this.autoCompleteType = 'email';
    this.setState({ data: { ...this.state.data, email: newValue } });
  };

  handleChangeFirstNameCorporate = (e) => {
    this.setState({ data: { ...this.state.data, firstname: e.target.value } });
  };

  handleChangeLastNameCorporate = (e) => {
    this.setState({ data: { ...this.state.data, lastname: e.target.value } });
  };

  handleChangeEmailCorporate = (e) => {
    this.setState({ data: { ...this.state.data, email: e.target.value } });
  };

  handleChangeCorpId = (e) => {
    if (!this.state.customer.corporateSelectedInfo) {
      this.state.customer.corporateSelectedInfo = {};
    }
    this.state.customer.corporateSelectedInfo.corpId = e.target.value;
    this.setState({ customer: this.state.customer });
  };

  handleChangeManagerName = (e) => {
    if (!this.state.customer.corporateSelectedInfo) {
      this.state.customer.corporateSelectedInfo = {};
    }
    this.state.customer.corporateSelectedInfo.managerName = e.target.value;
    this.setState({ customer: this.state.customer });
  };

  handleChangeManagerEmail = (e) => {
    if (!this.state.customer.corporateSelectedInfo) {
      this.state.customer.corporateSelectedInfo = {};
    }
    this.state.customer.corporateSelectedInfo.managerEmail = e.target.value;
    this.setState({ customer: this.state.customer });
  };

  handleChangeCostCentre = (e) => {
    if (!this.state.customer.corporateSelectedInfo) {
      this.state.customer.corporateSelectedInfo = {};
    }
    this.state.customer.corporateSelectedInfo.costCentre = e.target.value;
    this.setState({ customer: this.state.customer });
  };

  handleChangeDepartment = (e) => {
    if (!this.state.customer.corporateSelectedInfo) {
      this.state.customer.corporateSelectedInfo = {};
    }
    this.state.customer.corporateSelectedInfo.department = e.target.value;
    this.setState({ customer: this.state.customer });
  };

  handleChangeCorpDivision = (e) => {
    if (!this.state.customer.corporateSelectedInfo) {
      this.state.customer.corporateSelectedInfo = {};
    }
    this.state.customer.corporateSelectedInfo.corpDivision = e.target.value;
    this.setState({ customer: this.state.customer });
  };

  handleChangePromo = (e) => {
    this.setState({
      data: { ...this.state.data, promo: (e.target.value || '').toUpperCase() },
    });
  };

  handleDuration = (e) => {
    if (e.target.checked) {
      this.setState(
        {
          data: {
            ...this.state.data,
            duration: e.target.checked,
          },
          // package: this.props.newBooking.locationService.packagesRate[0]
        },
        this.etaFareCalculator
      );
    } else {
      this.setState(
        {
          data: { ...this.state.data, duration: e.target.checked },
          package: null,
        },
        this.etaFareCalculator
      );
    }
  };

  handleDriverChanged = (e) => {
    this.setState({
      data: { 
        ...this.state.data, 
        driver: e.target.value,
        vehicle: getVehicleWhenChangeDriver({
          oldVehicle: this.state?.data?.vehicle,
          pickupTime: this.state.data.time,
          drvInfo: {},
          vehicleTypes: [this.state.cartypeSelected.vehicleType],
          associateCarType: this.state.cartypeSelected?.dispatch
        }),
      },
      driver: {},
    });
  };

  handleMapLoad = (map) => {
    this._mapComponent = map;
    if (map) {
    }
  };

  handleRoundtripChange = e => {
    this.setState({
      data: { ...this.state.data, roundTrip: e.target.checked }
    }, () => {
      this.getDirectionAndDraw()
      this.etaFareCalculator()
    }
    );
  };

  handleRideSharingChange = (e) => {
    this.setState(
      { data: { ...this.state.data, rideSharing: e.target.checked } },
      this.etaFareCalculator
    );
  };

  handleCompanyChange = (e) => {
    this.props.corporateCompany.map((corp) => {
      if (corp._id == e.target.value) {
        const customerWithSelectedCompany = this.buildCustomerWithSelectedCompany(this.state.customer, corp);
        let paymentMethod = this.state.data && this.state.data.paymentMethod
        if(corp.isAirline) paymentMethod = 5
        if(corp) {
          let carTypeSelected = this.state.cartypeSelected || {}
          if(corp 
            && corp.vehicleTypes
            && corp.vehicleTypes.type === 'customized'
          ) {
            const vhcIds = _.get(corp, 'vehicleTypes.value', [])
            if(!vhcIds.includes(carTypeSelected?._id)) carTypeSelected = null
          }
          this.setState(
            {
              data: { 
                ...this.state.data, 
                company: e.target.value ,
                supplierCompanies: [],
                // carType: '',
                paymentMethod,
                isCustomerVip: corp.isCorporateVip || false
              },
              company: corp,
              cartypeSelected: carTypeSelected,
              customer: customerWithSelectedCompany,
            },
            () => {
              this.carTypeBaseLocation().then((data) => {
                this.carTypeBaseLocationDataPaser(data, null, true);
                this.etaFareCalculator();
                this.checkNearestDriver()
              });
            }
          );
        }
      }
    });
  };

  buildCustomerWithSelectedCompany = (customer, selectedCompany) => {
    let cus = Object.assign({}, customer);
    let corporateId = cus.passengerInfo && cus.passengerInfo.corporateId
    let corporateInfo = cus && cus.corporateInfo 
    if(selectedCompany.isAirline) {
      corporateId = cus.airlineId
      corporateInfo = {
        assistantEmail: cus.assistantEmail,
        corpDivision: cus.corpDivision || '',
        corpId: cus.corpId,
        corpPO: cus.corpPO || '',
        costCentre: cus.costCentre,
        department: cus.department,
        managerEmail: cus.managerEmail,
        managerName: cus.managerName,
        title: cus.title
      }
    }
    if (corporateId == selectedCompany._id) {
      cus = {
        ...cus,
        corporateSelectedInfo: Object.assign({}, corporateInfo || {}),
      };
    } else {
      cus = { ...cus, corporateSelectedInfo: {} };
    }
    return cus;
  };

  handleDurationPackageChange = (e) => {
    this.props.newBooking.locationService.packagesRate.map((pk) => {
      if (pk._id == e.target.value) {
        this.state.package = pk;
        this.state.data.packageRateId = pk._id;
      }
    });
    this.setState(this.state, this.etaFareCalculator);
  };

  handleMeetdriverChange = (e) => {
    this.setState(
      { data: { ...this.state.data, meetDriver: e.target.value } },
      this.etaFareCalculator
    );
  };

  handleChangeBookingReference = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        externalInfo: {
          ...this.state.data.externalInfo,
          bookingReference: e.target.value,
        },
      },
    });
  };

  handlePaymentMethodChange = (e) => {
    this.state.valid = {};
    this.setState({
      data: { ...this.state.data, paymentMethod: parseInt(e.target.value) }, originFare: this.state.etaFare
    }, () => {
      this.etaFareCalculator()
    });
    if (this.state.markupValue && NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(e.target.value)) && this.state.data.paymentMethod != e.target.value) {
      this.setState({
        etaFare: {
          ...this.state.originFare,
          markupDifference: 0,
        },
        fleetMarkup: 0,
        reasonMarkup: null,
        reasonMarkup: null,
        markupValue: 0,
        originFare: this.state.originFare,
        isFareEdited: false,
        reasonEditFare: null,
        prevEtaFare: {
          originFare: this.state.originFare,
          isFareEdited: false,
          reasonEditFare: null,
        }
      });
    }
  };

  handleCreditCardChange = (e) => {
    this.state.valid = {};
    if (e.target.value == 0) {
      this.setState({ credit: null });
    } else if (
      this.state.data.paymentMethod == paymentMethodNumber.personalCard
    ) {
      this.state.customer.credits.map((cre) => {
        if (
          cre.crossToken == e.target.value ||
          cre.localToken == e.target.value
        ) {
          this.state.credit = cre;
          this.setState(this.state);
        }
      });
    } else if (
      this.state.data.paymentMethod == paymentMethodNumber.corporateCard
    ) {
      this.state.company.credits.map((cre) => {
        if (
          cre.crossToken == e.target.value ||
          cre.localToken == e.target.value
        ) {
          this.state.credit = cre;
          this.setState(this.state);
        }
      });
    }
  };

  handleAddCardSuccess (cardAdded) {
    if (this.state.data.paymentMethod == paymentMethodNumber.personalCard) {
      const { customer } = this.state;
      customer.credits.push(cardAdded);
      this.setState({ customer, credit: cardAdded });
    }
    if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      const { company } = this.state;
      company.credits.push(cardAdded);
      this.setState({ company, credit: cardAdded });
    }
  }

  handlePromoApplyClick = () => {
    const isFareEdited = this.state.isFareEdited
    if (this.state.promo) {
      this.setState(
        { promo: null, data: { ...this.state.data, promo: '' } },
        () => {
          if(isFareEdited) {
            this.setState({
              etaFare: calculateFareWithPromoChange({
                auth: this.props.auth,
                promoInfo: null,
                etaFare: this.state.etaFare,
                data: this.state.data,
              })
            })
          } else {
            this.etaFareCalculator()
          }
        }
      );
    } else {
      const timezonePickUp = tzlookup(
        this.state.locationPickUp.lat,
        this.state.locationPickUp.lng
      );
      const localNow = moment().tz(timezonePickUp);
      const timeWithoutZone = moment(this.state.data.time).format(
        'YYYY-MM-DD HH:mm'
      );
      const localPickup = moment.tz(timeWithoutZone, timezonePickUp).format();

      let { dateMode } = this.state;
      let pickupTime = 'Now';

      if (dateMode == 'single') {
        pickupTime =
          this.state.data.time == 'Now'
            ? 'Now'
            : moment(this.state.data.time).format('YYYY-MM-DD HH:mm');
      } else {
        pickupTime = this.state.recurringTime;
      }

      const { recurringDays = [] } = this.state
      const recurring = {
        mode: dateMode,
        pickupTime: pickupTime,
        selectedDates: recurringDays.map(dateOb => moment(dateOb).format('YYYY-MM-DD'))
      };
      const promotionObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        recurring: recurring,
        pickupTime:
          this.state.data.time == 'Now'
            ? moment.utc(localNow).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            : moment.utc(localPickup).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        promotionCode: this.state.data.promo,
        userId: this.state.customer ? this.state.customer.userId : '',
        bookFrom: this.state.options && this.state.options.bookFrom || 'CC',
        currencyISO: this.props.newBooking.locationService.currency
          ? this.props.newBooking.locationService.currency.iso
          : this.props.auth.selectedFleet.currency.iso,
        geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
        serviceType: this.props.isShuttle ? 5 : 0, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
        etaFare: this.state.etaFare && this.state.etaFare.etaFare ? this.state.etaFare.etaFare : 0,
        paymentType: this.state.data.paymentMethod,
      };

      if (promotionObject.promotionCode) {
        this.props.newbookingActions
          .checkpromoCode(promotionObject)
          .then((data) => {
            if (data.returnCode === 200) {
              const newPromo = data.response;
              newPromo.promotionCode = this.state.data.promo;
              this.setState({ promo: newPromo }, 
                () => {
                  if(isFareEdited) {
                    this.setState({
                      etaFare: calculateFareWithPromoChange({
                        auth: this.props.auth,
                        promoInfo: newPromo,
                        etaFare: this.state.etaFare,
                        data: this.state.data,
                      })
                    })
                  } else {
                    this.etaFareCalculator()
                  }
              })
            } else if (data.returnCode) {
              this.context.notification(
                'error',
                I18n.t(`messages.promoMsg.${data.returnCode}`)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('messages.booking.Check_promo_error')
              );
            }
          });
      }
    }
  };

  handleChangeETAFare (fareEdited, originFare, reasonEditFare, isFareEdited, isReseted) {
    let shortTrip = this.props?.auth?.selectedFleet?.waiveOffCommission?.enable ? 
      (fareEdited?.etaFare > this.props?.auth?.selectedFleet?.waiveOffCommission?.amountByCurrencies[0]?.value
      ? false : true) : originFare?.shortTrip;

    this.setState({
      etaFare: {
        ...fareEdited,
        markupDifference: 0,
        shortTrip: shortTrip
      },
      data: {
        ...this.state.data,
        extraServices: resetService(isReseted, this.state.data.extraServices, originFare)
      },
      reasonMarkup: null,
      markupValue: 0,
      fleetMarkup: 0,
      originFare,
      isFareEdited,
      reasonEditFare,
      prevEtaFare: {
        originFare,
        isFareEdited,
        reasonEditFare,
      },
    });
  }

  handleChangeMarkupPrice (
    originFare,
    markupDifference,
    reasonMarkup,
    markupType,
    markupValue,
    isReseted,
    makeupPriceTotal,
    supplierEarning
  ) {
    this.setState({
      etaFare: {
        ...originFare,
        markupDifference,
        supplierEarningType : supplierEarning.supplierEarningType,
        editedSupplierEarning : supplierEarning.editedSupplierEarning,
        totalPayout: supplierEarning.totalPayout ? supplierEarning.totalPayout : originFare.totalPayout,
      },
      fleetMarkup: parseFloat(markupValue),
      reasonMarkup,
      markupType,
      markupValue: parseFloat(markupValue),
      originFare,
      isFareEdited: false,
      reasonEditFare: null,
      prevEtaFare: {
        originFare,
        isFareEdited: false,
        reasonEditFare: null,
      },
    });
  }

  handleAirlineChange = (e) => {
    this.setState({ data: { ...this.state.data, airline: e.target.value } });
  };

  handleFlightNumerChange = (e, suggest) => {
    let value = e.target.value || '';
    if (suggest) {
      value = suggest.newValue;
    }
    this.setState({
      data: {
        ...this.state.data,
        flightNumber: value.replace(/\s/g, '').toUpperCase(),
      },
    });
  };

  customerAutocompleteSlectedHandle = (event, { suggestion }) => {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    this.state.customer = suggestion
      ? Object.assign(this.state.customer || {}, suggestion)
      : null;
    if (this.state.company) {
      this.state.customer = this.buildCustomerWithSelectedCompany(
        this.state.customer,
        this.state.company
      );
    }
    const { fleetId } = this.props.auth.selectedFleet;
    this.props.customerActions
      .getPendingPaymentCustomer({ fleetId, userId: suggestion.userId })
      .then((data) => {
        const outStanding = _.get(data, 'res.outStanding', []).filter(
          (o) => o.amount > 0
        );
        this.setState(
          {
            data: {
              ...this.state.data,
              firstname: suggestion ? suggestion.firstName : '',
              lastname: suggestion ? suggestion.lastName : '',
              phone:
                suggestion && suggestion.phone !== suggestion.userId
                  ? suggestion.phone
                  : '',
              email: suggestion ? suggestion.email : '',
              outStanding: outStanding,
              tips:
                suggestion &&
                  suggestion.registerFrom == 'App' &&
                  suggestion.tips >= 0
                  ? suggestion.tips
                  : this.getDefaultTip(),
              isCustomerVip: this.state?.company?.isCorporateVip ? this.state?.company?.isCorporateVip : (suggestion ? !!suggestion.rank : false),
              oldVipCus: suggestion ? !!suggestion.rank : false,
              cardHolder: '',
              cardNumber: '',
              expiredDate: '',
              cvv: '',
              city: '',
              state: '',
              cardAddress: '',
              postalCode: '',
              country: '',
            },
            credit: null,
          },
          this.etaFareCalculator
        );
      });
  };

  handleAddNewCustomerSuccess = (customer) => {
    this.setState({
      customer,
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
    });
  };

  handleClientCaseMatterChange = (e) => {
    this.state.data.clientCaseMatter = e.target.value;
    this.setState({ data: this.state.data });
  };
  handleChangeOperatorNote = (e) => {
    this.setState({ data: { ...this.state.data, operatorNote: e.target.value } });
  };

  handleChargeCodeChange = (e) => {
    this.state.data.chargeCode = e.target.value;
    this.setState({ data: this.state.data });
  };

  CustomerVipClickHandle = () => {
    this.state.data.isCustomerVip = !this.state.data.isCustomerVip;
    this.setState({ data: this.state.data });
  };

  handleForceInfoWhenChangeSupplier = (suppliers, supplierInfoFirst) => {
    let resetPayment = false,
      newPaymentMethod = this.state.data?.paymentMethod || 0,
      resetCounterVehicleTextSearch = this.state.resetCounterVehicleTextSearch;
    const hasResetDrvInfo = checkResetDrvWhenChangeCompany(
      this.state?.driver?.company?.companyId,
      suppliers,
    );
    const hasResetVehicle = checkResetVehicleWhenChangeCompany(
      this.state?.data?.vehicle,
      suppliers,
    );
    if (!this.state.broadcastBooking) {
      if (
        !checkCompanyHasSupportPayment(
          this.props.paymentMethod,
          supplierInfoFirst,
          this.state.data?.paymentMethod
        )
      ) {
        newPaymentMethod = 0;
        resetPayment = true
      }
    }

    this.setState(
      {
        supplierCompanies: suppliers,
        data: {
          ...this.state.data,
          paymentMethod: newPaymentMethod,
          driver: hasResetDrvInfo ? '' : this.state.data?.driver,
          vehicle: hasResetVehicle ? {} : this.state?.data?.vehicle
        },
        driver: hasResetDrvInfo ? {} : this.state.driver,
        resetCounterVehicleTextSearch: hasResetVehicle ? ++resetCounterVehicleTextSearch : resetCounterVehicleTextSearch
      },
      () => {
        this.carTypeBaseLocation().then((service) => {
          if (service.ok && service.res) {
            const { hasReset, newVehicle } = checkResetCarWhenGetNewService(
              service.res.vehicleType,
              this.state?.cartypeSelected?.vehicleType,
              false,
              this.props.isShuttle
            );
            
            // kiểm tra có thay đổi vehicle hoặc payment mới gọi lại ETA
            if (hasReset) {
              this.setState(
                {
                  cartypeSelected: newVehicle,
                },
                () => {
                  if (newVehicle?.vehicleType) {
                    this.carTypeBaseLocation().then((res) => {
                      this.updateExtraService(res);
                      this.etaFareCalculator();
                    });
                    this.checkNearestDriver();
                  } else {
                    this.setState({
                      cartypeSelected: null,
                      etaFare: null,
                      etaFareMultiCar: [],
                    });
                  }
                }
              );
            } else if (resetPayment) {
              this.etaFareCalculator();
            } else if (suppliers.length == 1 && this.state?.etaFare?.supplierEarningType == 'default') {
              this.etaFareCalculator()
            }
          }
        });
      }
    );
  }

  // select company
  handleChangeSupplierCompanies = (newArrOption, selectingOption, isMulti) => {
    const { supplierIds, supplierInfoFirst } = convertDataWhenChangeSupplier(
      newArrOption, 
      selectingOption, 
      isMulti,
      this.props.auth,
      this.state.company,
      this.props.commonData
    ) || {}
      
    this.handleForceInfoWhenChangeSupplier(supplierIds, supplierInfoFirst)
  }

  driverAutocompleteSlectedHandle = (event, { suggestion }) => {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    this.setState({
      data: { 
        ...this.state.data, 
        driver: suggestion.driver.name,
        vehicle: getVehicleWhenChangeDriver({
          oldVehicle: this.state?.data?.vehicle,
          pickupTime: this.state.data.time,
          drvInfo: suggestion,
          vehicleTypes: [this.state.cartypeSelected.vehicleType],
          associateCarType: this.state.cartypeSelected?.dispatch
        }),
      },
      driver: Object.assign(this.state.driver, suggestion),
    }, this.debounceEtaFareCalculator);
  };

  setVehicleAutoComplete = (vehicle) => {
    this.setState({
      data: {
        ...this.state.data,
        vehicle: vehicle || {}
      }
    })
  }

  handleCarTypeChanged = (vehicleType) => {
    this.props.newBooking.locationService.vehicleType.map((car) => {
      if ((car.vehicleType || car.ty) == vehicleType) {
        this.state.cartypeSelected = car;
        this.state.data.isRoundTrip = false;

        this.state.data.driver = '';
        this.state.driver = '';
        this.resetPackageHourlyOption();
        if (!car.extraDestination) {
          this.state.isShowedExtraDestination = false;
          this.state.extraDestination = null;
        }
      }
    });

    this.setState(
      {
        data: { 
          ...this.state.data, 
          carType: vehicleType,
          vehicle: {}
        },
        cartypeSelected: this.state.cartypeSelected,
        resetCounterVehicleTextSearch: ++this.state.resetCounterVehicleTextSearch,
      },
      () => {
        this.getDirectionAndDraw();
        this.carTypeBaseLocation().then((data) => {
          if (data.ok) {
            this.updateExtraService(data.res);
            this.etaFareCalculator();
            if (data.res.crossZone) {
              this.state.data.dispatch3rd = false;
              this.getCrossZoneBaseLocation();
            }
          }
        });
        this.checkNearestDriver();
      }
    );
  };

  handleCarTypeChangedHydra = (vhc) => {
    if(vhc && !vhc.vehicleType) return ''
    let carTypeSelected = {}
    const vehicleType = _.get(
      this.props.newBooking, 
      'locationService.affiliateService.vehicleTypeHydraSplited', 
      []
    ) 
    vehicleType.map((car) => {
      if (
        (car.vehicleType || car.ty) == vhc.vehicleType &&
        car.providerOfVhc.vehicleType === vhc.providerOfVhc.vehicleType &&
        car.providerOfVhc.fleetName === vhc.providerOfVhc.fleetName
      ) {
        carTypeSelected = car;
        this.state.data.isRoundTrip = false;

        this.state.data.driver = '';
        this.state.driver = '';
        this.resetPackageHourlyOption();
        if (!car.extraDestination) {
          this.state.isShowedExtraDestination = false;
          this.state.extraDestination = null;
        }
      }
    });

    this.setState(
      {
        data: { ...this.state.data, carType: vhc.vehicleTyp },
        cartypeSelected: {
          ...carTypeSelected,
          carTypeLocalName: vhc.providerOfVhc && vhc.providerOfVhc.vehicleType
        },
        supplierVehicle: vhc.providerOfVhc && vhc.providerOfVhc.fleetId
      },
      () => {
        this.getDirectionAndDraw();
        this.carTypeBaseLocation().then((data) => {
          if (data.ok) {
            this.updateExtraService(data);
            this.etaFareCalculator();
            if (this.state.isHydraBooking) {
              this.state.data.dispatch3rd = false;
              this.getCrossZoneBaseLocation();
            }
          }
        });
        this.checkNearestDriver();
      }
    );
  };

  handleDateChanged = (e) => {
    this.selectingDate = false;
    if (e === 'Now') {
      if (this.state.data.time === e) return;
      this.state.data.driver = '';
      this.state.driver = {};
      this.setState({ 
        data: { 
          ...this.state.data, 
          time: e,
          dispatch_type: 0
        } 
      }, () => {
        this.etaFareCalculator();
        this.checkNearestDriver();
      });
    } else {
      const { data } = this.state;
      const prevTime = moment(data.time);
      if (data.time && data.time !== 'Now' && prevTime.isSame(e, 'day'))
        return false;
      this.state.data.time = e;
      if (this.state.isShowDriverCalendar && this.state.driverCalendarViewing) {
        this.handleDriverCalendarClick(this.state.driverCalendarViewing);
      }
      this.setState({ data: { ...this.state.data, time: e } }, () => {
        this.etaFareCalculator();
        this.checkNearestDriver();
      });
    }
  };

  onPickupTimeFocus () {
    this.selectingDate = true;
  }

  carTypeBaseLocation = () => {
    // if(this.checkDirtyLocation) return
    let options = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      supplierCompanies: this.state.supplierCompanies
    };
    if (this.state.locationPickUp) {
      options = {
        ...options,
        from: {
          geo: [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
          zipCode: this.state.locationPickUp.zipCode,
        },
        zipCodeFrom: this.state.locationPickUp.zipCode,
      };
    }
    if (this.state.locationDestination && this.state.locationDestination.lat) {
      options = {
        ...options,
        to: {
          geo: [
            this.state.locationDestination.lng,
            this.state.locationDestination.lat,
          ],
          zipCode: this.state.locationDestination.zipCode,
        },
        zipCodeTo: this.state.locationDestination.zipCode,
      };
      if (this.state.extraDestination && this.state.extraDestination.lat) {
        options = {
          ...options,
          to: {
            geo: [
              this.state.extraDestination.lng,
              this.state.extraDestination.lat,
            ],
          },
          extra: [
            {
              geo: [
                this.state.locationDestination.lng,
                this.state.locationDestination.lat,
              ],
            },
          ],
        };
      }
    }
    if (this.state.cartypeSelected && this.state.data.carType != '') {
      options = {
        ...options,
        vehicleType: this.state.cartypeSelected.vehicleType,
      };
    }
    if (
      (this.state.data.traveler_type == travelerType.Corporate || this.state.data.traveler_type == travelerType.Airline) &&
      this.state.company
    ) {
      options.corporateId = this.state.company._id;
      let supplier = this.state.company && this.state.company.supplier || {}
      if (supplier.type == 'custom') {
        options.limitCompany = supplier.value;
      }
    }
    let { isShuttle } = this.props
    if (!isShuttle) {
      options.bookingType = 'transport'
    }
    const notShowCarTypeCCWithoutRate = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.notShowCarTypeCCWithoutRate',
      false
    );
    const disableHourlyRate = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.disableHourlyRate',
      false
    );
    return this.props.newbookingActions
      .getCarTypeBaseLocation(options)
      .then((data) => {
        this.setState({
          isCheckSupportLocation: true,
          data: {
            ...this.state.data,
            duration: checkForceRateType(data.res, this.state.data.duration, disableHourlyRate)
          },
          cartypeSelected: checkForceCarTypeSelecting(
            data.res, 
            this.state.cartypeSelected, 
            notShowCarTypeCCWithoutRate, 
            this.props.isShuttle
          )
        });
        return data;
      });
  };

  carTypeBaseLocationDataPaser = (serviceResponse, selectdCar = null, skipCallDistanMatrix = false) => {
    const { isHydraBooking } = this.state
    const { newBooking = {}, auth } = this.props
    let service = serviceResponse
    if (
      isHydraBooking &&
      newBooking && newBooking.locationService && newBooking.locationService.affiliateService
    ) {
      this.state.data.paymentMethod = 1 // Cash;
      service = newBooking.locationService.affiliateService
    } else if (this.state.data.traveler_type == 1 || this.state.data.traveler_type == 2) {
      if (
        !this.props.paymentMethod.corporateTypes.find(
          (card) => card.value == this.state.data.paymentMethod
        )
      ) {
        let paymentFirst = this.props.paymentMethod.corporateTypes[0].value;
        this.state.data.paymentMethod = paymentFirst
        if ((paymentFirst == 6 || paymentFirst == 5) && this.props.isShuttle) {
          this.ValidatorCallback('payment_card', true)
        }
      }
    } else if (
      !this.props.paymentMethod.individualTypes.find(
        (card) => card.value == this.state.data.paymentMethod
      )
    ) {
      let paymentFirst = this.props.paymentMethod.individualTypes[0].value;
      this.state.data.paymentMethod = paymentFirst;
      if ((paymentFirst == 6 || paymentFirst == 5) && this.props.isShuttle) {
        this.ValidatorCallback('payment_card', true)
      }
    }
    let vehicleType = service.res && service.res.vehicleType || []
    if(this.state.isHydraBooking) {
      vehicleType = _.get(newBooking, 'locationService.affiliateService.vehicleTypeHydraSplited', [])
    }
    if (this.props.isShuttle) {
      vehicleType = vehicleType.filter((ob) => (ob.shuttle))
    }
    const notShowCarTypeCCWithoutRate = _.get(
      auth,
      'selectedFleet.generalSetting.notShowCarTypeCCWithoutRate',
      false
    );
    if(notShowCarTypeCCWithoutRate) {
      vehicleType = filterCarTypeWithoutAssignRate(vehicleType, this.state.company, this.props.isShuttle) 
    }
    if (vehicleType && vehicleType.length > 0) {
      const cartypes = vehicleType.filter(
        (v) =>
          ((!v.dispatchRideSharing && this.state.data.time !== 'Now') ||
            this.state.data.time === 'Now') &&
          (!v.availability || v.availability.cc)
      );
      let carType = this.state.cartypeSelected;
      if(this.state.isHydraBooking) {
        if(carType) {
          carType = vehicleType.find(
            (v) =>  (
                      (carType.vehicleType || carType.ty) == (v.vehicleType || v.ty)
                      && carType.providerOfVhc.fleetId === v.providerOfVhc.fleetId
                    )
          );
        }
        if(!carType) carType = vehicleType[0] || {}
      } else {
        if (carType) {
          carType = cartypes.find(
            (v) => (carType.vehicleType || carType.ty) == (v.vehicleType || v.ty)
          );
        }
        if (!carType) {
          if (this.state.data.time !== 'Now') {
            const newCartypes = _.dropWhile(cartypes, { superHelper: true });
            carType = newCartypes[0];
          } else {
            carType = cartypes[0];
          }
        }
        if (selectdCar) {
          carType = cartypes.find(
            (v) => selectdCar == v.vehicleType || selectdCar == v.ty
          );
        }
      }
      let currentPickupTime = this.state.data.time || 'Now'

      if(isHydraBooking && currentPickupTime === 'Now') { //  Nếu là hydra thì không support book now
        currentPickupTime = moment()
      }

      if (carType) {
        let providerId = ''
        if(isHydraBooking && !this.state.supplierVehicle) {
          providerId = _.get(carType, 'providers[0].fleetId', '')
        }
        this.setState({
          data: {
            ...this.state.data,
            carType: carType.vehicleType || carType.ty,
            isHydraBooking: isHydraBooking,
            time: currentPickupTime
          },
          cartypeSelected: carType,
          supplierVehicle: providerId ? providerId : this.state.supplierVehicle,
          providerId: providerId ? providerId : this.state.supplierVehicle,
        })
      }
    }
    return this.carTypeBaseLocation().then((data) => {
      if (data && data.res) {
        let newData = data.res
        if(isHydraBooking) newData = data.res.affiliateService
        this.updateExtraService(newData);
        if (
          this.state.data.packageRateId &&
          this.state.data.packageRateId.length > 0
        ) {
          service.res &&
          service.res.packagesRate &&
            service.res.packagesRate.map((pk) => {
              if (pk._id == this.state.data.packageRateId) {
                this.state.package = pk;
              }
            });
        }
        if (isHydraBooking) {
          this.state.data.dispatch3rd = false;
          this.getCrossZoneBaseLocation();
        }
        this.etaFareCalculator();
        if(!skipCallDistanMatrix) {
          this.checkNearestDriver();
        }
      }
    });
  };

  getCrossZoneBaseLocation = () => {
    const { supplierVehicle, isHydraBooking } = this.state
    const options = {
      fleetId: isHydraBooking && supplierVehicle 
        ? supplierVehicle 
        : this.props.auth.selectedFleet.fleetId,
      pricingType: 1,
      userId: this.props.auth.user._id,
    };
    if (this.state.locationPickUp) {
      options.airport = [
        [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
      ];
      if (this.state.locationDestination) {
        let to = [
          this.state.locationDestination.lng,
          this.state.locationDestination.lat,
        ];
        if (this.state.extraDestination && this.state.extraDestination.lat) {
          to = [
            this.state.extraDestination.lng,
            this.state.extraDestination.lat,
          ];
        }
        options.airport.push(to);
      }
    }
    if (this.state.cartypeSelected && this.state.data.carType != '') {
      options.vehicleType = this.state.cartypeSelected._id;
    }
    return this.props.newbookingActions
      .getCrossZoneRate(options)
      .then((data) => {
        if (data.ok && data.res) {
          if (data.res.response) {
            this.setState({ data: this.state.data }, () => {
              this.setState(
                { data: { ...this.state.data, tips: this.getDefaultTip() } },
                this.etaFareCalculator
              );
            });
          }
        }
      });
  };

  getDefaultPaymentMethod = () => {
    let paymentMethod = null;
    if (this.state.data.traveler_type == travelerType.Individual) {
      this.props.paymentMethod.individualTypes.map((pm) => {
        if (pm.type == 'fleetCard') {
          paymentMethod = pm;
        }
      });
      if (!paymentMethod) {
        paymentMethod = this.props.paymentMethod.individualTypes[0];
      }
    } else {
      this.props.paymentMethod.corporateTypes.map((pm) => {
        if (pm.type == 'fleetCard') {
          paymentMethod = pm;
        }
      });
      paymentMethod = this.props.paymentMethod.corporateTypes[0];
    }
    return paymentMethod;
  };

  newChauffeurRequestObjectBuilder = (forceAssign) => {
    const { isShuttle } = this.props
    const { 
      isHydraBooking = false, 
      data = {}, 
      supplierId
    } = this.state
    const bookingCurrency =
      this.props.newBooking &&
        this.props.newBooking.locationService &&
        this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency;
    const parsedTravelerType = parseInt(this.state.data.traveler_type);
    const crossZone = _.get(this.props.newBooking, 'locationService.crossZone', false)
    let { puPoints, doPoints, dateMode, recurringDays } = this.state

    let puPointsNotEmpty = filterPointEmpty(puPoints)
    puPointsNotEmpty = this.reOrderPointToSubmit(puPointsNotEmpty)
    let doPointsNotEmpty = filterPointEmpty(doPoints)
    doPointsNotEmpty = this.reOrderPointToSubmit(doPointsNotEmpty)

    let pickupTime = 'Now'

    if (dateMode == 'single') {
      if (isShuttle) {
        pickupTime = moment(this.state.data.time).format('YYYY-MM-DD HH:mm')
      } else {
        pickupTime = this.state.data.time == 'Now' ?
          'Now' :
          moment(this.state.data.time).format('YYYY-MM-DD HH:mm')
      }
    } else {
      pickupTime = this.state.recurringTime;
    }

    const recurring = {
      mode: dateMode,
      pickupTime: pickupTime,
      selectedDates: recurringDays.map(dateOb => moment(dateOb).format('YYYY-MM-DD'))
    };

    let isMultiPoints = (puPointsNotEmpty.length > 1 || doPoints.length > 1) ? true : false
    const markupDifference = this.state.etaFare && this.state.etaFare.markupDifference || 0;
    const object = {
      mapProvider: 'google',
      platform: 'Web',
      bookFrom: 'CC',
      'x-request-id': uuidv4({ msecs: new Date().getTime() }),
      dispatchType: isHydraBooking ?  0 : data.dispatch_type,
      externalInfo: {
        bookingReference: this.state.data?.externalInfo?.bookingReference ?? ''
      },
      dispatcherId: this.props.auth.user._id,
      isMultiPoints: isMultiPoints,
      forceAssign,
      fleetId: isHydraBooking
        ? this.state.supplierVehicle
        : this.props.auth.selectedFleet.fleetId,
      jupiter: isShuttle ? { jobType: 'shuttle' } : {},
      puPoints: puPointsNotEmpty,
      doPoints: doPointsNotEmpty || [],
      recurring: recurring,
      isFarmOut: isHydraBooking,
      trailNotes: this.state.trailNotes,
      request: {
        psgFleetId: this.props.auth.selectedFleet.fleetId,
        pickup: {
          geo:
            this.state.locationPickUp.lng === null ||
              this.state.locationPickUp.lat === null
              ? []
              : [this.state.locationPickUp.lng, this.state.locationPickUp.lat],
          city: this.state.locationPickUp.city,
          timezone: this.state.locationPickUp.timezone,
          cityName: this.state.locationPickUp.cityName,
          address: this.state.addressPickUp,
          zipCode: this.state.locationPickUp.zipCode,
          country: this.state.locationPickUp.countryCode,
          from:
            this.state.pickupFrom == locationType.google &&
              this.props.commonData.location &&
              this.props.commonData.location.isChina
              ? locationType.tencent
              : this.state.pickupFrom,
          instructionLink: this.state.locationPickUp.instructionLink || '',
          addressDetails: this.state.addressDetailsPickUp,
        },
        pickUpTime:
          this.state.data.time == 'Now'
            ? 'Now'
            : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
        vehicleType: this.state.cartypeSelected.dispatch || [
          this.state.cartypeSelected._id,
        ],
        fleetId: this.props.auth.selectedFleet.fleetId,
        special: '',
        tip: parseFloat(this.state.data.tips) || 0,
        promo: this.state.promo ? this.state.promo.promotionCode : '',
        promoValue: this.state.promo
          ? this.state.promo.type == 'Amount'
            ? currencyFormatter.format(-this.state.promo.value, {
              code: bookingCurrency.iso,
            })
            : `${-this.state.promo.value}%`
          : '',
        estimate: {
          originFare: this.state.originFare || {},
          isFareEdited: this.state.isFareEdited || false,
          reasonEditFare: this.state.reasonEditFare || '',
          isNormalFare: this.state.etaFare
            ? this.state.etaFare.normalFare
            : true,
          reasonMarkup: this.state.reasonMarkup || '',
          markupType: this.state.markupType || 'amount',
          markupValue: this.state.markupValue || 0,
        },
        markupDifference,
        type: 0,
        typeRate: 0,
        paymentType: this.state.data.paymentMethod,
        note: this.state.data.note,
        operatorNote: this.state.data.operatorNote,
        affiliateNote: this.state.data.affiliateNote,
        rideSharing: this.state.data.rideSharing,
        instructions: this.state.data.instructions
      },
      travelerType: this.getTravelerTypeText() === 'airline' ? 2 : parsedTravelerType,
      drvInfo: {},
      ack: 'vtdurh0n8gh',
      dispatch3rd: this.state.data.dispatch3rd,
      hwMetered: false,
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${this.props.auth.user.lastName || ''
          }`,
        role:
          this.props.auth.user.userType == userType.CorporateAdmin ||
            this.props.auth.user.userType == userType.CorporateUser
            ? 'corporate'
            : 'cc',
      },
      pricingType: isHydraBooking ? 1 : 0,
    };
    if (this.state?.supplierCompanies?.length > 0) {
      object.supplierCompanies = this.state.supplierCompanies
      object.request.companyName = this.state.supplierCompanies[0]?.name;
    }

    if (this.props?.auth?.user?.roles?.companyId && this.state.supplierCompanies.length == 0) {
      let listCompanies = this.getCompanyList()
      object.supplierCompanies = listCompanies.map((company) => company._id)
    }
    
    if (puPointsNotEmpty?.length > 0 && doPointsNotEmpty?.length > 0) {
      object.request.sessionKey = this.state.sessionKeyETAFare || '';
      object.request.estimate.fare = this.state.etaFare
        ? {
            ...this.state.etaFare,
            unroundedTotalAmt:
              this.state.etaFare.unroundedTotalAmt + markupDifference,
            totalWithoutPromo:
              this.state.etaFare.totalWithoutPromo + markupDifference,
            etaFare: this.state.etaFare.etaFare + markupDifference,
            fleetMarkup: this.state.fleetMarkup || 0,
          }
        : {};
    } else {
      object.request.estimate.fare = {};
    }

    if (
      this.state.locationDestination &&
      this.state.addressDestination &&
      this.state.addressDestination != ''
    ) {
      object.request.destination = {
        geo:
          this.state.locationDestination.lng === null ||
            this.state.locationDestination.lat === null
            ? []
            : [
              this.state.locationDestination.lng,
              this.state.locationDestination.lat,
            ],
        city: this.state.locationDestination.city,
        cityName: this.state.locationDestination.cityName,
        address: this.state.addressDestination,
        zipCode: this.state.locationDestination.zipCode,
        country: this.state.locationDestination.countryCode,
        from:
          this.state.destinationFrom == locationType.google &&
            this.props.commonData.location &&
            this.props.commonData.location.isChina
            ? locationType.tencent
            : this.state.destinationFrom,
        instructionLink: this.state.locationDestination.instructionLink || '',
        addressDetails: this.state.addressDetailsDestination,
      };
    } else {
      delete object.request.destination;
    }

    if (
      object.request.destination &&
      this.state.extraDestination &&
      this.state.extraDestination.lat &&
      this.state.extraDestination.lng
    ) {
      object.request.extraDestination = [object.request.destination];
      object.request.destination = {
        from:
          this.state.extraDestination.from == locationType.google &&
            this.props.commonData.location &&
            this.props.commonData.location.isChina
            ? locationType.tencent
            : this.state.extraDestination.from,
        address: this.state.extraDestination.address,
        geo:
          this.state.extraDestination.lng === null ||
            this.state.extraDestination.lat === null
            ? []
            : [
              this.state.extraDestination.lng,
              this.state.extraDestination.lat,
            ],
        zipCode: this.state.extraDestination.zipCode,
        offset: '',
        city: this.state.extraDestination.city,
        cityName: this.state.locationDestination.cityName,
        instructionLink: this.state.extraDestination.instructionLink || '',
      };
    } else {
      delete object.request.extraDestination;
    }

    // check request type
    if (this.isFromAirPort() 
      || (this.isFromAirPortHydra() && isHydraBooking) // check airport with hydra booking
    ) {
      object.request.type = 1;
    }
    if ((this.isToAirPort() 
        || (this.isToAirPortHydra() && isHydraBooking)
      ) 
      && object.request.type != 1) {
      object.request.type = 2;
    }

    if (this.state.data.roundTrip) {
      object.request.typeRate = 2;
      if (!(object.request.type == 1 || object.request.type == 2)) {
        object.request.type = 4;
      }
    }
    if (this.state.data.duration && this.state.package) {
      object.request.typeRate = 1;
      if (!(object.request.type == 1 || object.request.type == 2)) {
        object.request.type = 3;
      }
      object.request.packageRateId = this.state.package._id;
      object.request.packageRateName = this.state.package.name;
      object.request.duration = this.state.package.duration;
    }

    if (this.isFromAirPort() || this.isToAirPort() || this.isToExtraAirPort()) {
      if(this.state.isHydraBooking) {
        object.request.moreInfo = {
          flightInfo: {
            flightNumber: this.state.data.flightNumber || '',
            type: this.state.data.meetDriver,
          },
        };
      } else {
        object.request.moreInfo = {
          flightInfo: {
            flightNumber:
              this.props.newBooking.locationService.additionalService.fromAirport
                .flightInfo ||
                this.props.newBooking.locationService.additionalService.toAirportNew
                  .flightInfo
                ? this.state.data.flightNumber
                : '',
            type: this.state.data.meetDriver,
          },
        };
        if (this.state.flight) {
          object.request.moreInfo.flightInfo.airlineIcao = this.state.flight.icao;
          object.request.moreInfo.flightInfo.airlineIATA = this.state.flight.iata;
        }
        if (
          this.props.auth.selectedFleet?.process?.flight &&
          !_.isEmpty(this.state.flightSchedule)
        ) {
          object.request.moreInfo.flightInfo =
            this.state.flightSchedule;
          if (!/^[A-Za-z]/.test(object.request.moreInfo.flightInfo.flightNumber)) {
            object.request.moreInfo.flightInfo.flightNumber = object.request.moreInfo.flightInfo.iata + object.request.moreInfo.flightInfo.flightNumber;
          }  
        }
  
        if (
          object.request.type == 1 &&
          this.props.newBooking.locationService.additionalService.fromAirport
            .meetDriver
        ) {
          object.request.moreInfo.flightInfo.type = parseInt(
            this.state.data.meetDriver || 0
          );
        }
      }
    } else {
      if(object.request?.moreInfo)
        object.request.moreInfo.flightInfo = {}
    }

    if(parseInt(this.state.data.meetDriver) === 1 && this.isFromAirPort()) {
      object.request.welcomeMessage = this.state.data.welcomeMessage
    }

    if(this.state.data && this.state.data.minutesToArrive) {
      let {expected, value} = this.state.data.minutesToArrive
      value = parseInt(value)
      if (expected === 'before') value *= -1
      if (expected === 'samePickup') value = 0
      object.request.minutesToArrive = value
    }
    object.psgInfo = {
      email: this.state.data.email,
      firstName: this.state.data.firstname,
      lastName: this.state.data.lastname,
      fullName:
        (this.state.data.firstname ? `${this.state.data.firstname} ` : '') +
        (this.state.data.lastname ? this.state.data.lastname : ''),
      phone: '',
      rank: this.state.data.isCustomerVip ? 1 : 0,
    };

    if (this.state.data.phone && this.state.data.phone.length >= 6) {
      let phone = '';
      for (let i = 0; i < this.state.data.phone.length; i++) {
        const c = this.state.data.phone[i];
        if (c == '+' || parseInt(c) == 0 || !!parseInt(c)) {
          phone += c;
        }
      }
      object.psgInfo.phone = phone;
    }

    if (parsedTravelerType == travelerType.Corporate || parsedTravelerType == travelerType.Airline) {
      object.corporateInfo = {
        division: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.corpDivision
          : '',
        managerEmail: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.managerEmail
          : '',
        corpId: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.corpId
          : '',
        managerName: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.managerName
          : '',
        costCentre: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.costCentre
          : '',
        department: this.state.customer.corporateSelectedInfo
          ? this.state.customer.corporateSelectedInfo.department
          : '',
        clientCaseMatter: '',
        chargeCode: '',
        corporateId: this.state.company._id,
        name: this.state.company.companyInfo
          ? this.state.company.companyInfo.name
          : this.state.company.companyName,
      };
    }
    if (
      this.state.data.dispatch_type == 1 ||
      this.state.data.dispatch_type == 3
    ) {
      if(this.state?.driver?.phone) {
        object.drvInfo = {
          phone: this.state.driver.phone,
        };
      }
    }
    if (
      (this.state.data.paymentMethod == paymentMethodNumber.personalCard ||
        this.state.data.paymentMethod == paymentMethodNumber.corporateCard) &&
      !this.state.credit
    ) {
      this.context.notification('error', I18n.t('newbooking.Card_required'));
      return null;
    }
    if (this.state.data.paymentMethod == paymentMethodNumber.corporateCard) {
      if (!object.corporateInfo) {
        object.corporateInfo = {};
      }
      if (isHydraBooking) {
        object.psgInfo.creditInfo = {
          cardHolder: this.state.credit.cardHolder,
          localToken: this.state.credit.localToken,
          crossToken: this.state.credit.crossToken,
          cardMask: this.state.credit.cardMask,
        };
      } else {
        object.corporateInfo.creditInfo = {
          cardHolder: this.state.credit.cardHolder,
          cardType: this.state.credit.cardType,
          gateway: this.state.credit.gateway,
          localToken: this.state.credit.localToken,
          crossToken: this.state.credit.crossToken,
          cardMask: this.state.credit.cardMask,
        };
      }
    }
    if (this.state.data.paymentMethod == paymentMethodNumber.personalCard) {
      if (!object.psgInfo) {
        object.psgInfo = {};
      }
      object.psgInfo.creditInfo = {
        localToken: this.state.credit.localToken,
        crossToken: this.state.credit.crossToken,
        cardMask: this.state.credit.cardMask,
      };
    }

    if(this.state?.data?.vehicle?._id) {
      object.vehicle = {
        vehicleId: this.state?.data?.vehicle?._id
      }
    }

    if (
      (parsedTravelerType == travelerType.Corporate ||
        parsedTravelerType == travelerType.Airline ||
        this.state.data.paymentMethod == 4 ||
        this.state.data.paymentMethod == 5 ||
        this.state.data.paymentMethod == 7)
    ) {
      if (!object.corporateInfo) {
        object.corporateInfo = {};
      }
      object.corporateInfo.clientCaseMatter = this.state.data.clientCaseMatter;
      object.corporateInfo.chargeCode = this.state.data.chargeCode;
    }

    const {serviceName, value} = handleFinalServicesRequest(
      this.state.data.extraServices, 
      this.state.data.isNewSettingAdditionFee, 
      this.state.originFare,
      this.state.isFareEdited
    );
    object.request[serviceName] = value;

    //  check car type allow luggage
    const { cartypeSelected } = this.state;
    object.request.vehicleTypeRequest = cartypeSelected.vehicleType || cartypeSelected.ty
    if (isHydraBooking) {
      object.request.paxNumber = this.state.data.paxNumber;
      object.request.luggageNumber = this.state.data.luggageNumber;
      object.request.vehicleTypeRequest = cartypeSelected.carTypeLocalName
        ? cartypeSelected.carTypeLocalName
        : _.get(cartypeSelected, 'providers[0].vehicleType', '')
    } else if (
      cartypeSelected &&
      cartypeSelected.allowPaxNumber &&
      cartypeSelected.allowLuggageNumber
    ) {
      object.request.paxNumber = this.state.data.paxNumber;
      object.request.luggageNumber = this.state.data.luggageNumber;
    }
    return object;
  };

  handleCancelBookingClick = () => {
    this.setState({ isShowCancelMessage: !this.state.isShowCancelMessage });
  };

  escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  /* isFromAirPort = () => (
    this.state.locationPickUp
    && this.props.newBooking
    && this.props.newBooking.locationService
    && this.props.newBooking.locationService.additionalService
    && this.props.newBooking.locationService.additionalService.fromAirport
    && this.props.newBooking.locationService.additionalService.fromAirport.isActive
    && ((this.props.newBooking.locationService.fromAirport
      && this.props.newBooking.locationService.fromAirport._id)
      || (this.props.newBooking.locationService.airport
        && this.props.newBooking.locationService.airport[0]))
  ); */

  /* isToAirPort = () => (
    (this.state.locationDestination || this.state.extraDestination)
    && this.props.newBooking
    && this.props.newBooking.locationService
    && this.props.newBooking.locationService.additionalService
    && this.props.newBooking.locationService.additionalService.toAirportNew
    && this.props.newBooking.locationService.additionalService.toAirportNew.isActive
    && ((this.props.newBooking.locationService.toAirport
      && this.props.newBooking.locationService.toAirport._id)
      || (this.props.newBooking.locationService.airport
        && this.props.newBooking.locationService.airport[1]))
  ); */

  getSuggestionValue = (suggestion) => {
    if (this.autoCompleteType === 'firstname') {
      return suggestion.firstName ? suggestion.firstName : '';
    }
    if (this.autoCompleteType === 'lastname') {
      return suggestion.lastName ? suggestion.lastName : '';
    }
    if (this.autoCompleteType === 'email') {
      return suggestion.email ? suggestion.email : '';
    }
    return suggestion.phone ? suggestion.phone : '';
  };

  // Customer auto complete
  getSuggestions = (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    const travelerType =
      this.state.data.traveler_type == 0 ? 'individual' : 'corporation';
    let searchObject = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      str: escapedValue,
      travelerType: this.getTravelerTypeText(),
    };

    if(this.getTravelerTypeText() === 'corporation') {
      searchObject.corporateId = this.state.company && this.state.company._id 
    }

    if (this.getTravelerTypeText() === 'airline') {
      searchObject.airlineId = this.state.company && this.state.company._id
    }

    if (this.autoCompleteType === 'firstname') {
      searchObject = { ...searchObject, name: '1' };
    } else if (this.autoCompleteType === 'lastname') {
      searchObject = { ...searchObject, name: '1' };
    } else if (this.autoCompleteType === 'email') {
      searchObject = { ...searchObject, email: '1' };
    } else {
      searchObject.str = searchObject.str.replace(/\D/g, '');
    }

    if (searchObject.str.length == 0) {
      return [];
    }
    this.lastAutoCompleteRequestId = setTimeout(() => {
      this.props.customerAutocompleteActions
        .customerAutocomplete(searchObject)
        .then((data) => {
          if(data?.res?.list) {
            this.setState({
              suggestions: data.res.list,
            });
          }
        });
    }, 500);
    const regex = new RegExp(`\\b${escapedValue}`, 'i');

    return this.props.customerAutocomplete.data.filter((person) =>
      regex.test(this.getSuggestionValue(person))
    );
  };

  onSuggestionsFetchRequested = (data) => {
    if (data.reason !== 'input-focused') {
      this.setState({
        suggestions: this.getSuggestions(data.value),
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // driver auto complete render and handleAddressRemoved
  getDriverSuggestions = (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    const {
      auth: { selectedFleet },
    } = this.props;

    // const regex = new RegExp(`\\b${escapedValue}`, 'i');
    const offlineDriver = isCanAssignOfflineDriver(
      selectedFleet,
      this.state.data.time
    );
    const supplierCompanies = this.state?.data?.vehicle?.companyId ? [this.state?.data?.vehicle?.companyId] : this.state.supplierCompanies
    this.lastAutoCompleteRequestId = setTimeout(() => {
      this.props.driverAutocompleteActions
        .driverAutocomplete({
          fleetId: selectedFleet.fleetId,
          str: escapedValue,
          pickUpTime:
            this.state.data.time == 'Now'
              ? 'Now'
              : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
          vehicleTypeId: this.state.cartypeSelected._id,
          supplierCompanies: supplierCompanies,
          offlineDriver,
          corporateId: this.state.company && this.state.company._id,
          vehicleId: this.state?.data?.vehicle?._id || ''
        })
        .then(() => {
          this.setState({
            driversuggestions: regexpDriverAutoComplete(
              this.props.driverAutocomplete.data,
              escapedValue
            ),
          });
        });
    });

    // return this.props.driverAutocomplete.data.filter(person => (
    //   !escapedValue
    //   || regexName.test(this.getDriverSuggestionValue(person))
    //   || regex.test(person.vehicle.plateNumber)
    //   || regex.test(person.phone)
    //   || regex.test(person.firstName)
    //   || regex.test(person.lastName)
    // ));
    return regexpDriverAutoComplete(
      this.props.driverAutocomplete.data,
      escapedValue
    );
  };

  getDriverSuggestionValue = (suggestion) => suggestion.driver.name;

  onDriverSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      driversuggestions: this.getDriverSuggestions(value),
    });
  };

  onDriverSuggestionsClearRequested = () => {
    this.setState({
      driversuggestions: [],
    });
  };

  // Flight name auto complete
  flightAutocompleteSlectedHandle = (event, { suggestion }) => {
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    this.setState({
      data: { ...this.state.data, airline: suggestion.name },
      flight: suggestion,
    });
  };

  getFlightSuggestions = (value) => {
    const escapedValue = this.escapeRegexCharacters(
      value.trim().replace(/\s/g, '')
    );

    if (escapedValue === '') {
      return [];
    }
    if (this.lastAutoCompleteRequestId !== null) {
      clearTimeout(this.lastAutoCompleteRequestId);
    }
    const searchObject = {
      text: escapedValue,
      fleetId: this.props.auth.selectedFleet.fleetId,
    };

    if (escapedValue.length == 0) {
      return [];
    }
    this.lastAutoCompleteRequestId = setTimeout(() => {
      this.props.flightActions.getAllFlightNames(searchObject).then((data) => {
        if (data.ok && data.res) {
          this.setState({
            flightSuggestions: data.res,
          });
        }
      });
    }, 400);
    const regex = new RegExp(`\\b${escapedValue}`, 'i');

    return this.state.flightSuggestions.filter((flight) =>
      regex.test(this.getFlightSuggestionValue(flight))
    );
  };

  onFlightSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      flightSuggestions: this.getFlightSuggestions(value),
    });
  };

  onFlightSuggestionsClearRequested = () => {
    this.setState({
      flightSuggestions: [],
    });
  };

  // Flight name auto complete
  flightScheduleAutocompleteSlectedHandle = (event, { suggestion }) => {
    this.state.data.flightNumber = suggestion.iata + suggestion.flightNumber;
    this.setState({
      ...this.state,
      flightScheduleSuggestions: [],
      flightSchedule: suggestion,
    });
  };

  getFlightScheduleSuggestions = () => this.state.flightScheduleSuggestions;

  onFlightScheduleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      flightScheduleSuggestions: this.getFlightScheduleSuggestions(value),
    });
  };

  onFlightScheduleSuggestionsClearRequested = () => {
    this.setState({
      flightScheduleSuggestions: [],
    });
  };

  flightScheduleAplyButtonClick = () => {
    if (this.state.flightSchedule) {
      this.state.flight = null;
      this.state.data.airline = '';
      this.state.data.flightNumber = '';
      this.state.flightSchedule = null;
      this.state.flightScheduleSuggestions = [];
      this.setState(this.state);
    } else {
      const escapedValue = this.escapeRegexCharacters(
        this.state.data.flightNumber.replace(/\s/g, '')
      ).toUpperCase();

      if (escapedValue === '') {
        return [];
      }

      const regex = /^[\D]*[0-9]+$/g;
      if (!regex.test(escapedValue)) {
        this.context.notification(
          'error',
          I18n.t('messages.booking.Invalid_format')
        );
        return;
      }

      const IATA = escapedValue.replace(new RegExp('[0-9]', 'g'), '');
      if (IATA.length > 0) {
        if (this.lastAutoCompleteRequestId !== null) {
          clearTimeout(this.lastAutoCompleteRequestId);
        }
        const searchObject = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          airportIcao: this.props.newBooking.locationService.fromAirport
            ? this.props.newBooking.locationService.fromAirport.icao
            : this.props.newBooking.locationService.toAirport.icao,
          direction: this.props.newBooking.locationService.fromAirport
            ? 'destination'
            : 'origin',
          airlineIcao: IATA,
          pickUpTime:
            this.state.data.time == 'Now'
              ? 'Now'
              : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
          flightNumber: escapedValue.replace(IATA, ''),
          timezone: this.props.newBooking.locationService.fromAirport
            ? this.props.newBooking.locationService.fromAirport.timezone
            : this.props.newBooking.locationService.toAirport.timezone,
        };

        if (escapedValue.length == 0) {
          this.setState({
            flightScheduleSuggestions: [],
          });
        }
        this.props.loadingActions.showLoadingSpiner();
        this.props.flightActions
          .getFlightSchedules(searchObject)
          .then((data) => {
            if (data.ok && data.res) {
              let flights = data.res
              if (flights.length > 0) {
                const hidePreviousDateFlight = _.get(this.props.auth, 'selectedFleet.flightAware.hidePreviousDateFlight', false)
                if (hidePreviousDateFlight) {
                  const { locationPickUp, locationDestination } = this.state
                  let location = `${locationPickUp.lat},${locationPickUp.lng}`
                  if (this.isToAirPort()) {
                    location = `${locationDestination.lat},${locationDestination.lng}`
                  }
                  getTimezone({ location, fleetId: this.props.auth.selectedFleet.fleetId })
                    .then(res => {
                      const timezone = _.get(res, 'res.response.tz', '')
                      if (timezone) {
                        let time = moment().tz(timezone)
                        if (this.state.data.time !== 'Now' && this.state.data.time) {
                          time = moment(this.state.data.time).tz(timezone)
                        }
                        flights = flights.filter(ob => {
                          const scheduleTime = this.isFromAirPort() ? ob.arrivaltime : ob.departuretime;
                          if (time < moment(scheduleTime * 1000)) return true
                          return false
                        })
                        this.setState({
                          flightScheduleSuggestions: flights,
                        });
                      } else {
                        this.context.notification(
                          'error',
                          I18n.t('messages.booking.Can_not_get_timeZone')
                        );
                      }
                    })
                } else {
                  this.setState({
                    flightScheduleSuggestions: flights,
                  });
                }
              } else {
                this.context.notification(
                  'error',
                  I18n.t('messages.booking.Flight_not_found')
                );
                this.setState({
                  flightScheduleSuggestions: [],
                });
              }
            } else {
              this.context.notification(
                'error',
                I18n.t('messages.booking.Flight_not_found')
              );
            }
            this.props.loadingActions.hideLoadingSpiner();
          });
      } else {
        this.context.notification(
          'error',
          I18n.t('messages.booking.Invalid_format')
        );
      }
    }
  };

  handleDispatch3rdOnchange = (e) => {
    this.state.data.dispatch3rd = e.target.checked;
    this.setState({ data: this.state.data });
  };

  handleDriverCalendarClick = (driver) => {
    const fromDate = moment(this.state.data.time).add(-1, 'days');
    const toDate = moment(this.state.data.time).add(1, 'days');
    this.props.newbookingActions
      .getDriverReservationBooking({
        fleetId: this.props.auth.selectedFleet.fleetId,
        driverId: driver.driver._id,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.state.driverReservationBookings = data.res;
          this.state.driverCalendarViewing = driver;
          this.setState({ isShowDriverCalendar: true });
        } else {
        }
      });
  };

  handleCloseDriverCalendarClick = () => {
    // this.state.driversuggestions = [];
    this.setState({
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null,
    });
  };

  handleDriverReservationBookingDateChange = (num) => {
    this.setState({
      driverReservationDatePriview:
        this.state.driverReservationDatePriview + num,
    });
  };

  closeBookingForm = () => {
    this.props.newbookingActions.closeNewBookingForm();
    this.props.shuttleAction.closeShuttleBookingForm();
  }

  setTimeOutCreateButton = () => {
    setTimeout(() => {
      if (this.props.loadingBar.isShowSpiner) {
        this.props.loadingActions.hideLoadingSpiner();
        this.context.notification(
          'error',
          I18n.t('messages.booking.request_timeout')
        );
        this.setState({ preAuthBookId: null });
        this.closeBookingForm();
      }
    }, 60000);
  };

  handleSaveBookingClick = (e, forceAssign) => {
    e && e.preventDefault();
    const { recurringDays, 
      dateMode, 
      isHydraBooking,
      data
    } = this.state
    if(isHydraBooking) {
      if(
        data.paymentMethod == 1
      ) {
        this.context.notification('error', "This booking can't be farm out: selected payment type is not supported");
        return
      }
      if(data.time === 'Now') {
        this.context.notification('error', "This booking can't be farm out: support reservation only");
        return 
      }
    }
    if(_.isEmpty(this.state.cartypeSelected)) {
      this.context.notification('error', "Please select a car type to proceed the booking");
      return 
    }
    if(this.state.data && this.state.data.rideSharing) {
      const puPointsNotEmpty = filterPointEmpty(this.state.puPoints)
      const doPointsNotEmpty = filterPointEmpty(this.state.doPoints)
      if (puPointsNotEmpty.length > 1 || doPointsNotEmpty.length > 1) {
        this.context.notification('error', I18n.t('newbooking.rideSharing_multi_point'));
        return
      }
    }
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!this.state.locationPickUp) {
      this.context.notification('error', I18n.t('newbooking.Enter_a_location'));
      return;
    }
    if (!this.state.cartypeSelected) {
      this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_car_type')
      );
      return;
    } else {
      let validDropCountry = checkDropOffCountry(
        this.state.cartypeSelected.dropOffCountry,
        this.state.locationPickUp,
        this.state.locationDestination,
        this.state.isChina
      );
      if (!validDropCountry.valid) {
        validDropCountry.err == 0
          ? this.context.notification(
            'error',
            I18n.t('messages.booking.DropOff_country')
          )
          : this.context.notification(
            'error',
            I18n.t('messages.booking.Different_Country')
          );
        return;
      }
    }
    if (
      this.state.cartypeSelected.dispatchRideSharing &&
      !this.state.locationDestination
    ) {
      this.context.notification(
        'error',
        I18n.t(
          'messages.booking.Please_enter_destination_for_ridesharing_booking'
        )
      );
      return;
    }

    if (
      this.state.isHydraBooking &&
      !this.state.locationDestination
    ) {
      this.context.notification(
        'error',
        'Please enter destination for hydra booking'
      );
      return;
    }

    if (
      !this.state.data.paymentMethod ||
      !parseInt(this.state.data.paymentMethod) ||
      !checkCompanyHasSupportPayment(
        this.props.paymentMethod,
        this.getCompanyInfoFromIDCustome(),
        this.state.data?.paymentMethod,
        this.state.broadcastBooking
      )
    ) {
      return this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_payment_method')
      );
    }

    if (
      this.props.newBooking.locationService &&
      this.props.newBooking.locationService.crossZone &&
      !this.state.credit
    ) {
      this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_or_add_a_card')
      );
      return;
    }
    if (
      (this.state.data.paymentMethod == paymentMethodNumber.personalCard ||
        this.state.data.paymentMethod == paymentMethodNumber.corporateCard) &&
      !this.state.credit
    ) {
      this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_or_add_a_card')
      );
      return;
    }

    if (
      checkRequiredDispatch({
        dispatchType: data.dispatch_type,
        isBookNow: this.state?.data?.time == 'Now',
        driverPhone: this.state?.driver?.phone,
        vehicleId: this.state?.data?.vehicle?._id,
        isShuttle: this.props.isShuttle
      })
    ) {
      this.context.notification('error', 'Please select driver');
      return;
    }

    if(dateMode === 'recurring') {
      if(recurringDays && recurringDays.length === 0) {
        this.context.notification(
          'error',
          'Please add days for recurring'
        );
        return
      }
    }
    //
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      const { valid } = this.state;
      if (!valid.phoneStatus) {
        this.context.notification(
          'error',
          I18n.t('messages.booking.phone_invalid')
        );
        return;
      }
      if (dateMode === 'recurring') {
        if (recurringDays && recurringDays.length === 0) {
          this.context.notification(
            'error',
            'Please add days for recurring'
          );
          return
        }
        if (this.state?.valid?.recurringTime === false) {
          this.context.notification(
            'error',
            'Please select pick up time'
          );
          return
        }
      }
    }

    // check setting on/off payment method with corporate
    if([userType.CorporateAdmin, userType.CorporateUser].includes(this.props.auth.user.userType)) {
      const pmCheckedSetting = checkPaymentShow({
        data: this.state.data,
        auth: this.props.auth,
        traveler_type: this.state.data.traveler_type,
        companySelected: this.getCompanyInfoFromIDCustome(), 
        paymentMethodIndividuals: this.props.paymentMethod.individualTypes, 
        paymentMethodCorporates: this.props.paymentMethod.corporateTypes,
        corporateSelected: this.state.company
      })
      if(!pmCheckedSetting?.paymentMethodCorporates?.some(ob => ob.value === this.state.data.paymentMethod)) {
        return this.context.notification(
          'error',
          I18n.t('messages.booking.Please_select_payment_method')
        );
        return
      }
    }

    this.props.loadingActions.showLoadingSpiner();
    if (this.state.data.phone) {
      let customerObject = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        phone: this.state.data.phone
          .replace(new RegExp(' ', 'g'), '')
          .replace(new RegExp('-', 'g'), ''),
        firstName: this.state.data.firstname || '',
        lastName: this.state.data.lastname || '',
        email: this.state.data.email || '',
        tips: this.props.auth.selectedFleet.fleetFareId
          ? this.props.auth.selectedFleet.fleetFareId.tips
          : 0,
      };
      // SL-23585 Save 'company' for customer by Company of Booking request
      if (this.state.supplierCompanies?.length > 0) {
        let companyDataId = this.state.supplierCompanies[0];
        let companyData = [...this.props.commonData.companies, ...this.props.commonData.suppliers].find(item => item._id === companyDataId);
        if (companyData.type === 'supplier') {
          customerObject.company = {
            companyId: this.props.commonData.companies[0]?._id,
            name: this.props.commonData.companies[0]?.name,
          };
        }
        customerObject.company = {
          companyId: companyData?._id,
          name: companyData?.name,
        };
      }
      this.props.newbookingActions
        .checkCustomerBooking(customerObject)
        .then((data) => {
          if (data.ok && data.res && data.res.customer) {
            if (!this.state.customer) {
              this.state.customer = data.res.customer;
              if (this.state.data.traveler_type == travelerType.Corporate || this.state.data.traveler_type == travelerType.Airline) {
                this.state.customer.corporateSelectedInfo =
                  this.state.company;
              }
            }
            this.setState({ customer: this.state.customer }, () => {
              let bookingObj;
              bookingObj = this.newChauffeurRequestObjectBuilder(forceAssign);
              if (bookingObj) {
                // socketDispatchApi.emit(
                //   socketConfigs.send.newBooking,
                //   bookingObj
                // );
                this.props.newbookingActions.createNewBooking(bookingObj)
                  .then((res) => {
                    this.handleResultCreateBooking(res)
                  })
                this.setTimeOutCreateButton();
              } else {
                this.props.loadingActions.hideLoadingSpiner();
              }
            });
          } else {
            this.props.loadingActions.hideLoadingSpiner();
            this.context.notification(
              'error',
              I18n.t('messages.booking.Check_customer_error'),
              data.message
            );
          }
        });
    } else {
      let bookingObj;
      bookingObj = this.newChauffeurRequestObjectBuilder(forceAssign);
      if (bookingObj) {
        this.props.newbookingActions.createNewBooking(bookingObj)
          .then((res) => {
            this.handleResultCreateBooking(res)
          })
        this.setTimeOutCreateButton();
      } else {
        this.props.loadingActions.hideLoadingSpiner();
      }
    }
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      let oldMessages = this.state.errorMessages;
      if (!valid) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      this.setState({
        valid: this.state.valid,
        errorMessages: oldMessages,
      });
    }
  };

  isFromAirPort = () =>
    this.state.locationPickUp &&
    this.props.newBooking &&
    this.props.newBooking.locationService &&
    this.props.newBooking.locationService.additionalService &&
    this.props.newBooking.locationService.additionalService.fromAirport &&
    this.props.newBooking.locationService.additionalService.fromAirport
      .isActive &&
    ((this.props.newBooking.locationService.fromAirport &&
      this.props.newBooking.locationService.fromAirport._id) ||
      (this.props.newBooking.locationService.airport &&
        this.props.newBooking.locationService.airport[0]));

  isFromAirPortHydra = () =>
    this.state.locationPickUp &&
    this.props.newBooking &&
    this.props.newBooking.locationService &&
    this.props.newBooking.locationService.airport &&
    this.props.newBooking.locationService.airport[0]

  isToAirPortHydra = () =>
    this.state.locationDestination &&
    this.props.newBooking &&
    this.props.newBooking.locationService &&
    this.props.newBooking.locationService.airport &&
    this.props.newBooking.locationService.airport[1]

  isToAirPort = () =>
    this.state.locationDestination &&
    this.props.newBooking &&
    this.props.newBooking.locationService &&
    this.props.newBooking.locationService.additionalService &&
    this.props.newBooking.locationService.additionalService.toAirportNew &&
    this.props.newBooking.locationService.additionalService.toAirportNew
      .isActive &&
    ((this.props.newBooking.locationService.toAirport &&
      this.props.newBooking.locationService.toAirport._id) ||
      (this.props.newBooking.locationService.airport &&
        this.props.newBooking.locationService.airport[1]));

  isToExtraAirPort = () =>
    this.state.locationDestination &&
    this.state.extraDestination &&
    this.props.newBooking &&
    this.props.newBooking.locationService &&
    this.props.newBooking.locationService.additionalService &&
    ((this.props.newBooking.locationService.additionalService.toAirportNew &&
      this.props.newBooking.locationService.additionalService.toAirportNew
        .isActive) ||
      (this.props.newBooking.locationService.additionalService.fromAirport &&
        this.props.newBooking.locationService.additionalService.fromAirport
          .isActive)) &&
    this.props.newBooking.locationService.extraAirport &&
    this.props.newBooking.locationService.extraAirport[0] &&
    this.props.newBooking.locationService.extraAirport[0]._id;

  isValidManagerEmail = () => {
    if (
      this.state.customer.corporateSelectedInfo &&
      this.state.customer.corporateSelectedInfo.managerEmail
    )
      return Validation.validateMultiEmail(
        this.state.customer.corporateSelectedInfo.managerEmail
      );
    return true;
  };

  handleBookingRateChange = (e) => {
    const { value = 0 } = e.target || {};
    const newState = {};
    const data = {};
    if (parseInt(value) === 1) {
      data.roundTrip = false;
      data.duration = true;
    } else {
      /* this.resetPackageHourlyOption(); */
      data.duration = false;
      data.packageRateId = null;
      newState.package = null;
    }
    this.setState(
      {
        ...newState,
        data: {
          ...this.state.data,
          ...data,
        },
      },
      () => {
        this.getDirectionAndDraw();
        this.etaFareCalculator();
      }
    );
  };

  resetPackageHourlyOption = () => {
    this.state.data.duration = false;
    this.state.data.packageRateId = null;
    this.state.package = null;
  };

  updateExtraService = (data) => {
    // update Additional service of cartype
    const fleetData = data
    const additionalFees = _.get(fleetData, 'fleetInfo.additionalFees', 0) === 1
    let extraServicesResponse = []
    if (additionalFees && fleetData.fleetServices) {
      extraServicesResponse = fleetData.fleetServices
    } else if (fleetData.extraServices
      && fleetData.additionalServicesActive
      && !additionalFees
    ) {
      extraServicesResponse = fleetData.extraServices
    }
    if (extraServicesResponse) {
      const extraServices = [];
      const self = this;
      const currentCurrency = self.props.newBooking.locationService.currency;
      extraServicesResponse.forEach((service) => {
        let fee = 0;
        const serviceCurrency = service.serviceFeeByCurrencies.find(
          (c) => c.currencyISO == currentCurrency.iso
        );
        if (serviceCurrency) {
          fee = serviceCurrency.value;
        }
        let active = false;
        if (service.serviceType == 'Compulsory') {
          active = true;
        }
        extraServices.push({
          fee,
          serviceId: service._id,
          active,
          type: service.serviceType,
          name: service.serviceName,
          infoService: service,
          ...service
        });
      });
      this.setState({
        data: {
          ...this.state.data,
          extraServices,
          isNewSettingAdditionFee: additionalFees,
          supportPercent: fleetData?.fleetInfo?.supportPercent ?? false
        },
      });
    }
  };

  handleShowMoreCustomer = (show) => {
    this.setState({
      showMoreCustomer: show,
    });
  };

  showConfirmForceOverlap = () => {
    this.setState({
      showConfirmForceOverlap: {
        title: I18n.t('bookingdetail.confirm_force_overlap'),
        body: I18n.t('bookingdetail.confirm_force_overlap_message'),
        buttonTitle: I18n.t('bookingdetail.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('bookingdetail.No'),
      },
    });
  };

  handleCloseConfirmForceOverlapModal = () => {
    this.setState({ showConfirmForceOverlap: null });
  };

  handleConfirmForceOverlapClick = () => {
    this.handleSaveBookingClick(null, true);
    this.setState({ showConfirmForceOverlap: null });
  };

  changeDateMode = (value) => {
    if (value == 'single') {
      this.handleDateChanged('Now');
      this.updateToDate('');
      this.updateFromDate('');
    } else {
      this.handleDateChanged(moment());
    }
    this.setState({ dateMode: value });
  };

  updateFromDate = (value) => {
    let newFromDate = (value && value.format('YYYY-MM-DD')) || '';
    let { toDate } = this.state;
    if (newFromDate) {
      if (toDate && moment(newFromDate) > moment(toDate)) {
        this.setState({ fromDate: newFromDate, toDate: newFromDate });
        return;
      }
      this.setState({ fromDate: newFromDate });
    }
  };

  updateToDate = (value) => {
    let newToDate = (value && value.format('YYYY-MM-DD')) || '';
    let { fromDate } = this.state;
    if (newToDate) {
      if (fromDate && moment(newToDate) < moment(fromDate)) {
        this.setState({ toDate: newToDate, fromDate: newToDate });
        return;
      }
      this.setState({ toDate: newToDate });
    }
  };

  onChangeWeekDays = (weekDays) => {
    if (!weekDays) return;
    this.setState({ weekDays: weekDays });
  };

  reOrderPuPoint = (listOrder) => {
    let pointOrdered = [];
    // set order methoad of Point
    if (listOrder.length > 0) {
      pointOrdered = listOrder.map((point, id) => {
        point.order = id + 1;
        return point;
      });
    }
    return pointOrdered;
  };

  reOrderDoPoint = (puPoints, doPoints) => {
    let pointOrdered = [];
    if (doPoints.length > 0) {
      pointOrdered = doPoints.map((point, id) => {
        point.order = puPoints.length + ++id;
        return point;
      });
    }
    return pointOrdered;
  };

  setNewDoPuPointAddTraveler = (puPoints, doPoints) => {
    this.setState({
      puPoints: puPoints,
      doPoints: doPoints,
    });
  };

  checkForceDispatching = (supportLocalBooking, supportHydraBooking) => {
    const { dispatch_type = 0 } = this.state.data
    const user = this.props.auth && this.props.auth.user
    // Nếu user là corporate thì ưu tiên book local
    if(user.userType === userType.CorporateAdmin && supportLocalBooking) {
      return 0
    }
    if(
      (!supportLocalBooking && !supportHydraBooking) ||
      (!supportHydraBooking && this.state.isHydraBooking)
    ) return 0
    if(!supportLocalBooking && supportHydraBooking
      || (supportHydraBooking && this.state.isHydraBooking)
    ) return  -1
    return dispatch_type
  }

  setNewPuPoint = (newPoints = []) => {
    let hasEmptyPoint = newPoints.some((ob) =>
      _.isEmpty(_.get(ob, 'address.address', ''))
    );
    let { doPoints } = this.state;

    if (
      newPoints.length > 0 &&
      !(newPoints.length === 1 && hasEmptyPoint) &&
      newPoints.some((ob) => !_.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      let firstPuPoint = newPoints[0];
      if (hasEmptyPoint) {
        let newPointsNoEmpty = filterPointEmpty(newPoints);
        firstPuPoint = newPointsNoEmpty[0];
      }
      const dispatchType = this.checkForceDispatching(
        firstPuPoint.supportLocalBooking,
        firstPuPoint.supportHydraBooking
      );
      this.setState(
        {
          locationPickUp: firstPuPoint.address,
          addressPickUp:
            firstPuPoint.addressPointText ||
            (firstPuPoint.address && firstPuPoint.address),
          loadingPickUp: true,
          pickupFrom: firstPuPoint.pickupFrom,
          center: firstPuPoint.address,
          addressDetailsPickUp: firstPuPoint.addressDetails,
          supportHydraBooking: firstPuPoint.supportHydraBooking || false,
          supportLocalBooking: firstPuPoint.supportLocalBooking || false,
          isHydraBooking: dispatchType === -1 ? true : false,
          data: {
            ...this.state.data,
            dispatch_type: dispatchType,
          },
          puPoints: this.reOrderPuPoint(newPoints),
          doPoints: this.reOrderDoPoint(newPoints, doPoints),
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok && data.res) {
              this.updateExtraService(data);
              this.carTypeBaseLocationDataPaser(data);
            }
            this.getDirectionAndDraw();
          });
        }
      );
    } else {
      this.setState(
        {
          locationPickUp: null,
          addressPickUp: '',
          loadingPickUp: false,
          supportHydraBooking: false,
          supportLocalBooking: false,
          isCheckSupportLocation: false,
          puPoints: this.reOrderPuPoint(newPoints),
          doPoints: this.reOrderDoPoint(newPoints, doPoints),
        },
        () => {
          this.getDirectionAndDraw();
        }
      );
    }
  }

  getCompanyInfoFromIDCustome = () => {
    return getSupplierInfoFromID([...this.props.commonData.companies, ...this.props.commonData.suppliers], this.state.supplierCompanies[0])
  }

  setNewDoPoint = (newPoints = []) => {
    let hasEmptyPoint = newPoints.some(ob => _.isEmpty(_.get(ob, 'address.address', '')))

    if (newPoints.length > 0
      && !(newPoints.length === 1 && hasEmptyPoint)
      && newPoints.some(ob => !_.isEmpty(_.get(ob, 'address.address', '')))
    ) {
      let lastPoPoint = newPoints[newPoints.length - 1]
      if (hasEmptyPoint) {
        let newPointsNoEmpty = filterPointEmpty(newPoints)
        lastPoPoint = newPointsNoEmpty[newPointsNoEmpty.length - 1]
      }
      this.setState(
        {
          locationDestination: lastPoPoint.address,
          loadingDestination: true,
          pickupFrom: lastPoPoint.pickupFrom,
          center: lastPoPoint.address,
          addressDetailsDestination: lastPoPoint.addressDetails,
          doPoints: this.reOrderDoPoint(this.state.puPoints, newPoints),
        },
        () => {
          this.carTypeBaseLocation().then((data) => {
            if (data.ok) {
              if (data.res && data.res.crossZone) {
                this.state.data.dispatch3rd = false;
                this.getCrossZoneBaseLocation();
              }
              if(_.isEmpty(this.state.cartypeSelected)) {
                this.carTypeBaseLocationDataPaser(data);
              }
              this.getDirectionAndDraw();
            }
          });
        }
      );
    } else {
      this.setState(
        {
          locationDestination: null,
          loadingDestination: false,
          doPoints: this.reOrderDoPoint(this.state.puPoints, newPoints),
        },
        () => {
          this.getDirectionAndDraw();
        }
      );
    }
  }

  checkZoneSupportedWithPointComponent = async (params) => {
    let result = await this.props.newbookingActions.checkZoneSupportedWithPoint(
      params
    );
    const { auth } = this.props;
    if (
      !checkAllSupplierHasSupportPickup(result, auth, [...this.props.commonData.companies, ...this.props.commonData.suppliers])
    ) {
      result = {};
    }

    if (
      result?.res?.valid ||
      (result?.res?.crossZone && !this.state?.company) || // Trường hợp book hydra không chọn corporate
      (result?.res?.crossZone && this.state?.company?.isCreateRoaming) // Trường hợp book hydra chọn corporate có on Create roaming
    ) {
      // Khac zone hien tai thi reset nhung cai lien quan company
      const newZoneId = result?.res?.zonesLocal?.[0]?._id
      if (this.state?.zonePickup?._id !== newZoneId) {
        const compannyAvailable = this.getCompanyList(result?.res?.zonesLocal?.[0])
        const { auth, commonData } = this.props,
          { supplierCompanies } = this.state,
          checkReset = checkResetSupplierByZone(newZoneId, auth, supplierCompanies, commonData, compannyAvailable, {});
        if(checkReset.hasReset) {
          this.setState({
            supplierCompanies: checkReset.suppliers,
            data: {
              ...this.state.data,
              paymentMethod: 1,
              driver: '',
            },
            driver: {},
            cartypeSelected: null,
          });
        }
      }
      this.setState({
        zonePickup: result?.res?.zonesLocal?.[0] || {},
      });
    } else {
      this.setState({
        zonePickup: null,
      });
    }
    return result;
  }

  setRecurringTime = (time) => {
    this.setState({ recurringTime: time });
  };

  setRecurringDays = (days = []) => {
    this.setState({ recurringDays: days });
  };

  getCompanyList = (newZone) => {
    const { data = {}, company, zonePickup} = this.state,
      { commonData, auth } = this.props,
      userType = _.get(auth, 'user.userType', '')
    return filterCompanyList(data.traveler_type, company, [...commonData.companies, ...commonData.suppliers], userType, newZone ? newZone : zonePickup)
  }

  render () {
    const { auth, commonData, isShuttle } = this.props;
    const {
      customer,
      cartypeSelected,
      isShowedExtraDestination,
      extraDestination,
      doPoints,
      puPoints,
      data = {},
      company = {},
      zonePickup
    } = this.state;
    const userType = _.get(auth, 'user.userType', '')
    const companyList = this.getCompanyList()
    const showNavigationPUDO = _.get(auth, 'selectedFleet.generalSetting.showNavigationPUDO', false);
    const limitPoint = _.get(auth, 'selectedFleet.transport', {});
    const { moduleSettings } = auth.selectedFleet;
    const isAirlineBusiness = _.get(auth, 'selectedFleet.airlineBusiness.enable')
    const hasRecurring = _.get(auth, 'selectedFleet.recurring.enable', false)
    const multiPointCalFareMode = _.get(auth, 'selectedFleet.multiPointCalFareMode', '')
    const { location } = commonData;
    const { fleetId } = (auth && auth.selectedFleet) || {};
    const isRoundTrip = checkRoundTrip({fare: this.state?.etaFare, locationService: this.props?.newBooking?.locationService});
    const isHourly =
      this.state.locationPickUp &&
      this.props.newBooking.locationService &&
      (this.props.newBooking.locationService.vehicleType ||
        this.props.newBooking.locationService.carTypeAffiliate) &&
      this.props.newBooking.locationService.additionalService &&
      (this.props.newBooking.locationService.crossZone ||
        this.props.newBooking.locationService.additionalService.hourly) &&
      this.props.newBooking.locationService.packagesRate &&
      this.props.newBooking.locationService.packagesRate.length > 0;

    const bookingCurrency =
      this.props.newBooking &&
        this.props.newBooking.locationService &&
        this.props.newBooking.locationService.currency
        ? this.props.newBooking.locationService.currency
        : this.props.auth.selectedFleet.currency;

    const isHasExtraDestination =
      isShowedExtraDestination && extraDestination && extraDestination.lat;
    let isSuperHelper =
      CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status) &&
        this.state.data.superHelper
        ? true
        : cartypeSelected && cartypeSelected.superHelper;

    let puPointsNotEmpty = filterPointEmpty(puPoints)
    let doPointsNotEmpty = filterPointEmpty(doPoints)

    const isMultiPuDoNotEmpty = checkMultiPuDoNotEmpty(puPointsNotEmpty, doPointsNotEmpty)
    const isAirlineCorporate = this.state.company && this.state.company.isAirline || false

    return (
      <ReactCSSTransitionGroup
        transitionName="bounceOutLeft"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
        transitionAppearTimeout={500}
        transitionAppear
        transitionLeave
      >
        {this.state.isShowCancelMessage ? (
          <Modal
            show
            backdrop
            key="modal-form"
            dialogClassName="confirm-dialog"
            className="confirm"
            onHide={this.handleCancelBookingClick}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="newbooking.CANCEL_BOOKING" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Translate value="newbooking.CANCEL_BOOKING_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.onClose();
                }}
                className="btn-save"
              >
                <Translate value="newbooking.Yes" />
              </Button>
              <Button
                className="btn-cancel"
                onClick={this.handleCancelBookingClick}
              >
                <Translate value="newbooking.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ''
          )}

        <Confirm
          confirm={this.state.showConfirmForceOverlap}
          handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
          handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
        />
        <div key="newbooking-form" className="newbooking-form">
          <div className="bookingTitle">
            <span className="bookingTitle__text">
              {
                isShuttle
                  ? <Translate value="Sidebar.Shuttle" />
                  : <Translate value="Sidebar.Chauffeur" />
              }
            </span>
          </div>
          <DriverCalendarInfo
            isShowDriverCalendar={this.state.isShowDriverCalendar}
            driverReservationBookings={this.state.driverReservationBookings}
            driverReservationDatePriview={
              this.state.driverReservationDatePriview
            }
            handleDriverReservationBookingDateChange={
              this.handleDriverReservationBookingDateChange
            }
            data={this.state.data}
            onClose={this.handleCloseDriverCalendarClick}
            driverCalendarViewing={this.state.driverCalendarViewing}
            fieldKey="time"
          />
          <Container fluid>
            <Row>
              <Col xs={12} md={9} className="mt">
                <Container fluid>
                  <Row>
                    <Col xs={12} md={4} className="info-content">
                      <FormGroupTitle>
                        <Translate value="newbooking.BOOKING_INFORMATION" />
                      </FormGroupTitle>
                      <DateMode
                        pickupTime={this.state.data.time}
                        setMinutesToArrive={this.setMinutesToArrive}
                        cartypeSelected={this.state.cartypeSelected}
                        setRecurringDays={this.setRecurringDays}
                        recurringDays={this.state.recurringDays}
                        nowButton
                        data={this.state.data}
                        format24Hour={
                          auth.selectedFleet && auth.selectedFleet.format24Hour
                        }
                        showETA={auth.selectedFleet.cue && auth.selectedFleet.cue.showETA}
                        isHydraBooking={this.state.isHydraBooking}
                        isSuperHelper={isSuperHelper}
                        changeDateMode={this.changeDateMode}
                        onChangePickUpHour={this.onChangePickUpHour}
                        onChangePickUpMin={this.onChangePickUpMin}
                        handleDateChanged={this.handleDateChanged}
                        dateMode={this.state.dateMode}
                        onChangeWeekDays={this.onChangeWeekDays}
                        weekDays={this.state.weekDays}
                        hasRecurring={hasRecurring}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        jobType={this.props.isShuttle ? 'shuttle' : ''}
                        updateToDate={this.updateToDate}
                        updateFromDate={this.updateFromDate}
                        toDate={this.state.toDate}
                        fromDate={this.state.fromDate}
                        recurringTime={this.state.recurringTime}
                        setRecurringTime={this.setRecurringTime}
                        ValidatorCallback={this.ValidatorCallback}
                        minutesToArrive={this.state.data.minutesToArrive}
                      />
                      <RouteInfo
                        route={this.state.route}
                        corporate={this.state.company}
                        isSubmited={this.state.isSubmited}
                        isHydraBooking={this.state.isHydraBooking}
                        listDirtyLocation={this.state.listDirtyLocation}
                        setDirtyLocation={this.setDirtyLocation}
                        puPoints={this.state.puPoints}
                        doPoints={this.state.doPoints}
                        isChina={this.state.isChina}
                        setNewPuPoint={this.setNewPuPoint}
                        limitPoint={limitPoint}
                        setNewDoPoint={this.setNewDoPoint}
                        jobType={this.props.isShuttle ? 'shuttle' : ''}
                        recentPlaces={
                          customer &&
                          customer.passengerInfo &&
                          customer.passengerInfo.recentPlaces
                        }
                        checkPointInZoneSupported={
                          this.checkZoneSupportedWithPointComponent
                        }
                        fleetId={fleetId}
                        partyLocation={moduleSettings.partyLocation}
                        format24Hour={
                          auth.selectedFleet && auth.selectedFleet.format24Hour
                        }
                        auth={auth}
                        newbookingActions={this.props.newbookingActions}
                        customerActions={this.props.customerActions}
                        autocompleteCustomer={
                          this.props.messageAction.autocompleteCustomer
                        }
                        setNewDoPuPointAddTraveler={this.setNewDoPuPointAddTraveler}
                        traveler_type={this.state.data.traveler_type}
                        corporateId={this.state.company && this.state.company._id || ''}
                        // options={{ bounds: this.state.mapDefaultBound }}
                        options={this.state.optionAutocomplete}
                      />
                    </Col>
                    <Col xs={12} md={4} className="info-content">
                      <TravellerInfo
                        user={this.props.auth && this.props.auth.user}
                        data={this.state.data}
                        handleChangeTravelerType={this.handleChangeTravelerType}
                        selectedFleet={
                          this.props.auth && this.props.auth.selectedFleet
                        }
                        corporateCompany={this.props.corporateCompany}
                        handleCompanyChange={this.handleCompanyChange}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        handleChangePhone={this.handleChangePhone}
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        customerAutocompleteSlectedHandle={
                          this.customerAutocompleteSlectedHandle
                        }
                        handleBlurPhone={this.handleBlurPhone}
                        promo={this.state.promo}
                        CustomerVipClickHandle={this.CustomerVipClickHandle}
                        ValidatorCallback={this.ValidatorCallback}
                        phoneStatus={this.state.phoneStatus}
                        handleChangeFirstNameCorporate={
                          this.handleChangeFirstNameCorporate
                        }
                        newBooking={this.props.newBooking}
                        handleChangeEmailCorporate={this.handleChangeEmailCorporate}
                        customer={this.state.customer}
                        handleChangeDepartment={this.handleChangeDepartment}
                        handleChangeCorpDivision={this.handleChangeCorpDivision}
                        handleChangeManagerName={this.handleChangeManagerName}
                        handleChangeManagerEmail={this.handleChangeManagerEmail}
                        handleChangeCostCentre={this.handleChangeCostCentre}
                        handleChangeCorpId={this.handleChangeCorpId}
                        handleChangeFirstName={this.handleChangeFirstName}
                        handleChangeLastNameCorporate={
                          this.handleChangeLastNameCorporate
                        }
                        traveler_type={this.state.traveler_type}
                        isShowAll={this.state.showMoreCustomer}
                        handleShowMoreCustomer={this.handleShowMoreCustomer}
                        cartypeSelected={this.state.cartypeSelected}
                        isShuttle={this.props.isShuttle}
                      />

                      <DispatchInfoChauffeur
                        user={this.props.auth && this.props.auth.user}
                        data={this.state.data}
                        corporate={this.state.company}
                        supportHydraBooking={this.state.supportHydraBooking}
                        supportLocalBooking={this.state.supportLocalBooking}
                        handleRideSharingChange={this.handleRideSharingChange}
                        handleChangeDispatchType={this.handleChangeDispatchType}
                        newBooking={this.props.newBooking}
                        isSubmited={this.state.isSubmited}
                        settingActions={this.props.settingActions}
                        supplierCompanies={this.state.supplierCompanies}
                        driversuggestions={this.state.driversuggestions}
                        setVehicleAutoComplete={this.setVehicleAutoComplete}
                        onDriverSuggestionsFetchRequested={this.onDriverSuggestionsFetchRequested}
                        onDriverSuggestionsClearRequested={this.onDriverSuggestionsClearRequested}
                        driverAutocompleteSlectedHandle={this.driverAutocompleteSlectedHandle}
                        handleDriverChanged={this.handleDriverChanged}
                        cartypeSelected={this.state.cartypeSelected}
                        driver={this.state.driver}
                        bookingCurrency={bookingCurrency}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        handleDispatch3rdOnchange={this.handleDispatch3rdOnchange}
                        ValidatorCallback={this.ValidatorCallback}
                        valid={this.state.valid}
                        handleDriverCalendarClick={this.handleDriverCalendarClick}
                        isShuttle={this.props.isShuttle}
                        handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
                        handleChangeNote={this.handleChangeNote}
                        isNotesEnable
                        isHydraBooking={this.state.isHydraBooking}
                      />
                      <BookingInfoChauffeur
                        supplierCompanies={this.state.supplierCompanies}
                        resetCounterVehicleTextSearch={this.state.resetCounterVehicleTextSearch}
                        zonePickup={this.state.zonePickup}
                        setSupplier={this.setSupplier}
                        // handleCompanyDriverChange={this.handleCompanyDriverChange}
                        handleChangeSupplierCompanies={this.handleChangeSupplierCompanies}
                        commonData={this.props.commonData}
                        corporate={this.state.company}
                        companyList={companyList}
                        isFareEdited={this.state.isFareEdited}
                        locationPickUp={this.state.locationPickUp}
                        newBooking={this.props.newBooking} // new booking common data
                        user={this.props.auth && this.props.auth.user} // logged user
                        selectedFleet={
                          this.props.auth && this.props.auth.selectedFleet
                        } // current fleet
                        data={this.state.data} // booking form data
                        cartypeSelected={this.state.cartypeSelected}
                        handleDateChanged={this.handleDateChanged}
                        handleCarTypeChanged={this.handleCarTypeChanged}
                        handleCarTypeChangedHydra={this.handleCarTypeChangedHydra}
                        supplierVehicle={this.state.supplierVehicle}
                        supplierId={this.state.supplierId}
                        nearestDriver={this.state.nearestDriver}
                        isHourly={isHourly}
                        time={this.state.data.time}
                        isRoundTrip={isRoundTrip}
                        etaFareProps={this.props.etaFare}
                        etaFareState={this.state.etaFare}
                        handleRoundtripChange={this.handleRoundtripChange}
                        handleBookingRateChange={this.handleBookingRateChange}
                        handleRideSharingChange={this.handleRideSharingChange}
                        handleDurationPackageChange={this.handleDurationPackageChange}
                        locationDestination={this.state.locationDestination}
                        isHasExtraDestination={isHasExtraDestination}
                        onChangePickUpHour={this.onChangePickUpHour}
                        onChangePickUpMin={this.onChangePickUpMin}
                        nowButton
                        onPickupTimeFocus={this.onPickupTimeFocus}
                        isShuttle={this.props.isShuttle}
                        handleChangeNote={this.handleChangeNote}
                        handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
                        isMultiPuDoNotEmpty={isMultiPuDoNotEmpty}
                        multiPointCalFareMode={multiPointCalFareMode}
                        etaFareMultiCar={this.state.etaFareMultiCar || []}
                        nearestDriverList={this.state.nearestDriverList || []}
                        isHydraBooking={this.state.isHydraBooking}
                      />
                    </Col>
                    <Col xs={12} md={4} className="">
                      <FlightInfo
                        isChauffeur
                        isHydraBooking={this.state.isHydraBooking}
                        newBooking={this.props.newBooking}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        flightScheduleSuggestions={this.state.flightScheduleSuggestions}
                        onFlightScheduleSuggestionsFetchRequested={
                          this.onFlightScheduleSuggestionsFetchRequested
                        }
                        onFlightScheduleSuggestionsClearRequested={
                          this.onFlightScheduleSuggestionsClearRequested
                        }
                        flightScheduleAutocompleteSlectedHandle={
                          this.flightScheduleAutocompleteSlectedHandle
                        }
                        flightSchedule={this.state.flightSchedule}
                        handleFlightNumerChange={this.handleFlightNumerChange}
                        flightScheduleAplyButtonClick={this.flightScheduleAplyButtonClick}
                        bookingCurrency={bookingCurrency}
                        handleMeetdriverChange={this.handleMeetdriverChange}
                        data={this.state.data}
                        flightNumber={this.state.data.flightNumber}
                        locationPickUp={this.state.locationPickUp}
                        locationDestination={this.state.locationDestination}
                        extraDestination={this.state.extraDestination}
                        handleChangeNote={this.handleChangeNote}
                        handleChangeWelcomeMessage={this.handleChangeWelcomeMessage}
                        welcomeMessage={this.state.data.welcomeMessage}
                        meetDriver={this.state.data.meetDriver || '0'}
                      />
                      <PassengerLuggageNumber
                        data={this.state.data}
                        isHydraBooking={this.state.isHydraBooking}
                        selectedFleet={
                          this.props.auth && this.props.auth.selectedFleet
                        }
                        newBooking={this.props.newBooking}
                        cartypeSelected={this.state.cartypeSelected}
                        onChangePaxNumber={this.handleChangePaxNumber}
                        onChangeLuggageNumber={this.handleChangeLuggageNumber}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        ValidatorCallback={this.ValidatorCallback}
                      />
                      {
                        !this.state.isHydraBooking &&
                        <ExtraServiceInfo
                          newBooking={this.props.newBooking}
                          isChauffeur
                          data={this.state.data}
                          etaFare={this.state.etaFare}
                          locationPickUp={this.state.locationPickUp}
                          cartypeSelected={this.state.cartypeSelected}
                          selected={this.state.data.extraServices}
                          onSelectAdditionalService={this.handleAdditionalService}
                          selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                          handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
                          handleChangeNote={this.handleChangeNote}
                          isNotesEnable
                          isShuttle={this.props.isShuttle}
                        />
                      }
                      {!this.state.isHydraBooking && 
                          <PromoInfo
                            handleChangePromo={this.handleChangePromo}
                            data={this.state.data}
                            promo={this.state.promo}
                            newBooking={this.props.newBooking}
                            locationPickUp={this.state.locationPickUp}
                            handlePromoApplyClick={this.handlePromoApplyClick}
                            valid={this.state.valid}
                            isSubmited={this.state.isSubmited}
                            selectedFleet={
                              this.props.auth && this.props.auth.selectedFleet
                            }
                            handleChangeTip={this.handleChangeTip}
                            bookingCurrency={bookingCurrency}
                            ValidatorCallback={this.ValidatorCallback}
                        />
                      }
                      <PaymentInfo
                        data={this.state.data}
                        handleChangeBookingReference={this.handleChangeBookingReference}
                        handlePaymentMethodChange={this.handlePaymentMethodChange}
                        companySelected={this.getCompanyInfoFromIDCustome()}
                        paymentMethod={this.props.paymentMethod}
                        fleetSupplierId={this.state.supplierVehicle}
                        isHydraBooking={this.state.isHydraBooking}
                        newBooking={this.props.newBooking}
                        corporateTypes={this.props.paymentMethod.corporateTypes}
                        airlineTypes={this.props.paymentMethod.airlineTypes}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        handleCreditCardChange={this.handleCreditCardChange}
                        handleAddCardSuccess={this.handleAddCardSuccess}
                        reloadCustomerTSYSGateway={this.reloadCustomerTSYSGateway}
                        reloadCorporateCompany={this.reloadCorporateCompany}
                        credit={this.state.credit}
                        customer={this.state.customer}
                        selectedFleet={
                          this.props.auth && this.props.auth.selectedFleet
                        }
                        auth={this.props.auth}
                        company={this.state.company}
                        handleClientCaseMatterChange={
                          this.handleClientCaseMatterChange
                        }
                        handleChangeOperatorNote={
                          this.handleChangeOperatorNote
                        }
                        handleChargeCodeChange={this.handleChargeCodeChange}
                        ValidatorCallback={this.ValidatorCallback}
                        locationPickUp={this.state.locationPickUp}
                        paymentGetwayDisabledNewBooking={
                          paymentGetwayDisabledNewBooking
                        }
                        handleAddNewCustomerSuccess={this.handleAddNewCustomerSuccess}
                        isShuttle={this.props.isShuttle}
                        isAirlineCorporate={isAirlineCorporate}
                      />
                      <Notes 
                        handleChangeNote={this.handleChangeNote}
                        data={this.state.data}
                        isNotesEnable
                        cartypeSelected={this.state.cartypeSelected}
                        selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                        handleChangeNoteSuperHelper={this.handleChangeNoteSuperHelper}
                        handleChangeAffiliateNote={this.handleChangeAffiliateNote}
                        isHydraBooking={this.state.isHydraBooking}
                        handleChangeOperatorNote={this.handleChangeOperatorNote}
                      />
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={12} md={3} style={{ height: '100%' }}>
                <div className="booking-map-container">
                  <BookingMapChauffeur
                    showNavigationPUDO={showNavigationPUDO}
                    handleMapLoad={this.handleMapLoad}
                    center={this.state.center}
                    locationPickUp={this.state.locationPickUp}
                    puPoints={this.state.puPoints}
                    doPoints={this.state.doPoints}
                    handlePickupDrapEnd={this.handlePickupDrapEnd}
                    pickupFrom={this.state.pickupFrom}
                    locationDestination={this.state.locationDestination}
                    handleDestinationDrapEnd={this.handleDestinationDrapEnd}
                    destinationFrom={this.state.destinationFrom}
                    extraDestination={this.state.extraDestination}
                    handleExtraDestinationDrapEnd={this.handleExtraDestinationDrapEnd}
                    directions={this.state.directions}
                    isChina={location ? location.isChina : false}
                  />
                </div>
                <div style={{ clear: 'both' }} />
                  <TripEstimate
                    cartypeSelected={this.state.cartypeSelected}
                    puPoints={puPointsNotEmpty}
                    doPoints={doPointsNotEmpty}
                    supplierCompanies={this.state.supplierCompanies}
                    companyList={companyList}
                    newBooking={this.props.newBooking}
                    isTransportBooking={this.props.isTransportBooking}
                    driverCompany={this.getCompanyInfoFromIDCustome()}
                    fleetMarkup={this.state.fleetMarkup}
                    isHydraBooking={this.state.isHydraBooking}
                    destinationFrom={this.state.destinationFrom}
                    addressDestination={_.get(this.state.data, 'booking.request.destination.address', {})}
                    data={this.state.data}
                    etaFare={this.state.etaFare}
                    reasonMarkup={this.state.reasonMarkup}
                    markupType={this.state.markupType}
                    markupValue={this.state.markupValue}
                    promo={this.state.promo}
                    selectedFleet={this.props.auth && this.props.auth.selectedFleet}
                    user={this.props.auth && this.props.auth.user}
                    auth={this.props.auth}
                    disDur={this.state.disDur}
                    locationPickUp={this.state.locationPickUp}
                    bookingDetailActions={this.state.bookingDetailActions}
                    locationDestination={this.state.locationDestination}
                    isHasExtraDestination={isHasExtraDestination}
                    bookingPermission={this.props.bookingPermission}
                    prevEtaFare={this.state.prevEtaFare}
                    handleChangeETAFare={this.handleChangeETAFare}
                    handleChangeMarkupPrice={this.handleChangeMarkupPrice}
                    driver={this.state.driver}
                    bookType={this.props.isShuttle ? BOOK_TYPE_FEE.shuttle : BOOK_TYPE_FEE.transport}
                    settingActions={this.props.settingActions}
                    fareSettings={this.props.fareSettings}
                    commonData={this.props.commonData}
                  />
                {!this.props.isShuttle && <div style={{marginTop: "20px"}}>
                  <TrailNotesNewBooking
                      notification={this.context.notification}
                      listTrailNotes={this.state.trailNotes}
                      handleAddTrailNotes={this.handleAddTrailNotes}
                      handleDeleteTrailNotes={this.handleDeleteTrailNotes}
                      uploadService={this.props.uploadService}
                      fleetId={this.props.auth.selectedFleet.fleetId}
                    />
                  </div>}
                <IframeAddCard 
                  isShowModal={this.state.isShowIframePreAuthModal}
                  urlIframe={this.state.urlIframePreAuth}
                  auth={this.props.auth}
                  title={'Verification'}
                  closeModal={this.closeModalIframePreAuthModal}
                />
                <div xs={12} className="new-booking-button-container">
                  <ButtonToolbar className="text-center">
                    <Button
                      className="btn-save"
                      onClick={e => this.handleSaveBookingClick(e)}
                      disabled={
                        Validation.isStringEmpty(this.state.addressPickUp)
                        // || (this.state.data.dispatch_type == 1 && this.state.data.driver == null)
                        // || (this.props.isShuttle && Validation.isStringEmpty(this.state.locationDestination))
                        // || (this.state.listDirtyLocation && this.state.listDirtyLocation.length > 0)
                        // || (this.checkDirtyLocation())
                      }
                    >
                      <Translate value="newbooking.Create" />
                    </Button>
                    <Button className="btn-cancel" onClick={this.handleCancelBookingClick}>
                      <Translate value="newbooking.Cancel" />
                    </Button>
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
NewBooking.contextTypes = {
  notification: PropTypes.func,
};
NewBooking.propTypes = {
  auth: PropTypes.object,
  newBooking: PropTypes.object,
};

function mapStateToProps (state) {
  return {
    newBooking: state.newBooking,
    auth: state.auth,
    customerAutocomplete: state.customerAutocomplete,
    corporateCompany: state.corporateCompany,
    driverAutocomplete: state.driverAutocomplete,
    paymentMethod: state.paymentMethod,
    etaFare: state.etaFare,
    socket: state.socket,
    commonData: state.commonData,
    loadingBar: state.loadingBar,
    bookingPermission: state.menuHandle.bookingPermission,
    language: state.i18n
  };
}

function mapDispatchToProps (dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
    customerAutocompleteActions: bindActionCreators(
      customerAutocompleteActions,
      dispatch
    ),
    corporateCompanyActions: bindActionCreators(
      corporateCompanyActions,
      dispatch
    ),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    driverAutocompleteActions: bindActionCreators(
      driverAutocompleteActions,
      dispatch
    ),
    etaFareActions: bindActionCreators(etaFareActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
    flightActions: bindActionCreators(flightActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    tencentMapActions: bindActionCreators(tencentMapActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    messageAction: bindActionCreators(messageAction, dispatch),
    shuttleAction: bindActionCreators(shuttleAction, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewBooking);
